import React, {useState,useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from './common/Footer';
import Header from './common/Header';
import WeatherApi from './component/WeatherApi';
import AboutUS from './pages/Aboutus';
import Download from './pages/Download';
import Home from './pages/Home';
import Project from './pages/Project';
import Services from './pages/Services';
import BlogWeather from './utility/BlogWeather';
import BootCamp from './pages/BootCamp';
import Announce from './common/Announce';
import PersonalBusiness from './utility/PSBMS/PersonalBusiness';
import Login from './pages/Login';
import Register from './pages/Register';
import DashBoard from './Dashboard';
import { AuthProvider } from './AuthenContext';
import { auth } from './config';
import { onAuthStateChanged } from 'firebase/auth'; 
import Profile from './Users/Profile';
import PrivateRoute from './PrivateRoute'

import GetDataFire from './utility/PSBMS/Datas/GetDataFire';
import NoSignIn from './NoSignIn';
import NoPageFound from './NoPageFound';
import ContactUs from './ContactUs';
import AddingRecords from './utility/PSBMS/components/AddingRecords';
import DeletingRecord from './utility/PSBMS/components/DeletingRecord';
import MonthlyGoal from './utility/PSBMS/components/MonthlyGoal';
import RegisterCourse from './School/Register';
import GetUserProfile from './Users/GetUserProfile';
import GiftExachange from './JrApp/GiftExhange';
import AddMemberLige from './Lige/addMemberLige';


const App = () => {
  const [currentUser,setCurrentUser] = useState(JSON.parse(localStorage.getItem('currentUser')))
  const [username,setUsername] = useState(JSON.parse(localStorage.getItem('currentUser')))
  const searchInput = ''
  useEffect(() => {
   
    onAuthStateChanged(auth, (currentUser) => {
      // console.log('we are updating the user oooh')
      localStorage.setItem('currentUser',JSON.stringify(currentUser))
      setCurrentUser(currentUser)
    })
  },[])
  return ( 
    <div>
      <AuthProvider value={{currentUser}}>
        <Announce/>
        {/* <Header user={username}/> */}
        <GetUserProfile/>
        <div>
          
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="home" element={<Home/>}/>
    
              {/* User */}
              <Route path="signin" element={<Login setCurrentUser={setCurrentUser}/>}/>
              <Route path="signup" element={<Register/>}/>

              {/*  */}
              <Route path="services" element={<Services/>}/>

              #Project
              <Route path="project" element={<Project/>}/>
              <Route path="/project/AddingMemberLige" element={<AddMemberLige/>}/>
              {/* <Route path="/project/viewlistmembers" */}
              {/* Download routes */}
              <Route path="Download" element={<Download/>}/>

              {/* Utility */}
              <Route path="aboutus" element={<AboutUS/>}/>
              <Route path="weather" element={<WeatherApi/>}/>
              <Route path='bootcamp' element = {<BootCamp/>}/>
              <Route path='contactUs' element = {<ContactUs/>}/>
              <Route path='jr-app/giftexchange' element = {<GiftExachange/>}/>

              {/* register */}
              <Route path="bootcamp/register" element={<RegisterCourse/>} />
              
              <Route  element={<PrivateRoute/>}>
                <Route path='/dashboard' element={<DashBoard/>}/>
                <Route path="/Psbms" element={<GetDataFire/>}/>
                <Route path='/profile' element={<Profile/>}/>
                <Route path='/psbms/addingRecords' element={<AddingRecords/>}/>
                <Route path='/psbms/deletingRecords' element={<DeletingRecord/>}/>
                <Route path='/psbms/monthlygoal' element={<MonthlyGoal/>} />
              </Route>

              {/* No found route */}
              <Route path="*" element={<NoPageFound/>}/>

            </Routes>
            
        </div>
        <Footer/>
      </AuthProvider>
    </div>
   );
}
 
export default App;
