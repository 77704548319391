import React, { useState, useEffect } from 'react';
import { useAuthValue } from '../../../AuthenContext';
import { useNavigate } from 'react-router-dom';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../config';

const GoalMonthly = (props) => {
    const data = props.dataSet; 
    const [total,setTotal] = useState(); 
    const {currentUser} = useAuthValue();
    const [message,setMessage] = useState()
    const navigate = useNavigate();
    const filterMonthData = data.filter(obj => {
        return obj.userid === currentUser.uid
    } )
    const totalAmount = () => {
        var totaly= 0
        filterMonthData.map((item) => {
            totaly += Number(item.amount)
        })
        setTotal(totaly)
    }
    useEffect(() => {
        totalAmount()
    },[filterMonthData])

    const Alldeletion = ( ) => {
        navigate('')
        for(var i =0;i<filterMonthData.length;i++){
            // console.log(filterMonthData[i].key);
            const ref = doc(db,"monthlygoal", filterMonthData[i].key); 
            deleteDoc(ref)
            .then(() => {
               setMessage('deleted successfully')
            })
            .catch((error) => {
                setMessage('unsuccessfull operations')
            })
        }
        // navigate('/dashboard')
    }
    return ( 
        <div>
            <h3>Monthly Expenses Planning</h3>
            <table className='table table-bordered border-secondary'>
                <thead>
                    <tr className='text-center'>
                        <th>EXPENSES</th>
                        <th>AMOUNT</th>
                        <th>PRIORITY</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filterMonthData.map((item) => 
                            <tr className='text-center' key={item.key}>
                                <td>{item.expensesName}</td>
                                <td><strong>$</strong> {item.amount}</td>
                                <td>{item.priority}</td>
                            </tr>                        
                        )
                    }
                    <tr className='bg-success text-white fw-bold text-center'>
                        <td>Total</td>
                        <td>$ {total}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>           
            <button className="btn btn-secondary" onClick = {Alldeletion}>Delete All</button> &nbsp;
        </div>
     );
}
 
export default GoalMonthly;