import React from 'react'

const LigeHeader = () => {
  return (
    <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-secondary ">
            <a class="navbar-brand" href="#">Lige</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                <li class="nav-item active">
                    <a class="nav-link" href="AddingMemberLige">Add New Member <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="project">View List of Member</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Add New activity</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link disabled" href="#">View List of Activity</a>
                </li>
                </ul>
            </div>
        </nav>
    </div>
  )
}

export default LigeHeader