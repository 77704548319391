import React, { Component } from 'react'
import { useAuthValue } from '../AuthenContext';
import { signOut } from 'firebase/auth';
import { auth } from '../config';
import Channel from '../features/Channel';

const Profile = () => {
    const {currentUser} = useAuthValue()
    return ( 
        <div className='container'>
            <h1 className="text-center">Chat with us</h1>
            <Channel/>
        </div>
    );
}
 
export default Profile;