import React from 'react'

const Project = () => {
    return ( 
        <div className='container'>
            <h1>JR Hi-Tech APP Project</h1>
            <p><h3>LOGICIEL INTEGRE DE GESTION D’EGLISE (LIGE)</h3>
                Faisant suite à notre réunion du 18 avril 2014 tenue en mon bureau, je me fais le plaisir de vous donner les précisions aux différentes préoccupations que vous avez exprimées.
                <ol>
                    <li>Gestion des membres 
                    <p>Ce module remplace celui intitulé initialement « Gestion du personnel ». Ce module ne concerne que les membres de l’église ; c’est une base des données 
                        qui traitera la liste des membres de l’église y compris les différentes informations inscrites sur leur carte de membre (ex. Nom, Age, N° de carte, 
                        Date délivrance de la carte, Province..). Pour ceux qui sont ouvriers ou exerçant une fonction particulière au sein de l’église, leur service 
                        ou département d’appartenance sera renseigné comme information supplémentaire.<br/>
                        <a decoration='none' href='project/AddingMemberLige'>Add a Member</a><br/>
                    </p>
                    </li>

                    <li>Activités réalisées
                        <p>Ce module sert à identifier quelles activités on veut réaliser, avec quel coût, par qui et tout en précisant à quel moment on va les exécuter. Il permet 
                            ainsi de savoir déjà en avance ce qui est prévu de faire et quand les différentes ressources (matérielles, financières et humaines) doivent être disponibles 
                            pour les activités. L’avantage d’une planification c’est que si on a une planification (annuelle, semestrielle, trimestrielle ou mensuelle) fixée sur 
                            un tableau ou sur un papier on peut venir à chaque moment et voir ce qu’on a prévu à un moment donné. Comme ça on n’oublie pas ce qu’on voulait faire.
                            <br/><a decoration='none' href='project/AddingActivityLige'>Add an Activity</a><br/>
                        </p>
                    </li>
                </ol>
            </p>
        </div>
     );
}
 
export default Project;