import React, { Component } from 'react'
import SearchBarInput from './component/SearchBarInput';
import NoFound from './Asset/NoPage/NoFound.png'; 

const NoPageFound = () => {
    return ( 
        <div>
            <div className="container col-xl-10 col-xxl-8 px-4 py-5">
                <div className="row align-items-center g-lg-5 py-5">
                    <div className="col-lg-7 text-center text-lg-start">
                        <h3 className="display-4 fw-bold lh-1 mb-3">Ooops</h3>
                        <h6>Relax, take it easy. Keep fresh your mind!</h6>
                        <p className="col-lg-10 fs-4">You find our error page 404, this is not your fault, it is just a little bit 
                        mistakes. But don't worry, as an apology, we give you an extra 10% discount on our hosting products. Use code coupon 404. 
                        To get your extra discount.</p>
                        <a href='/home'>
                            <button className="btn btn-primary">Home</button>
                        </a>
                    </div>
                    <div className="col-md-10 mx-auto col-lg-5">
                        <img className="p-4 p-md-5  rounded  " height="500em" width="550em" src={NoFound} alt='pic'/>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default NoPageFound