import React from 'react'

const Announce = () => {
    const hour = new Date().toLocaleDateString('en-us',{hour:'2-digit',minute:'2-digit', second:'2-digit'})
    return ( 
        <div>
            {/* <header className="d-flex bg-danger justify-content-center text-white  py-2" style={{textAlign:'center'}}>
                <span>You can reach us from this email <br></br>info@jrhitech.com<br/><strong>{hour}</strong></span>
            </header> */}
            <div className='d-flex bg-dark justify-content-center text-white  py-2'>
                <div className="row">
                    {/* <div className="col-auto">
                        You can reach us at <a><span className='text-info shadow'>info@jrhitech.com</span></a>
                    </div> */}
                    <div className="col-auto">
                        {/* We are under consturction !!! */}
                        "Innovative Solutions for a Connected World"
                    </div>
                    {/* <div className="col-auto">
                        <strong>{hour}</strong>
                    </div> */}
                </div>
            </div>
            
            {/* <header className='bg-light'>
                <div className="px-0 py-0 ">
                    <div className=" d-flex flex-wrap justify-content-center">
                        <div className="text-end py-2 pb-3">
                            <button type="button" className="btn btn-success text-white me-4">Login</button>
                            <button type="button" className="btn btn-warning">Sign-up</button>
                            <div className='text-info'>{hour}</div>
                        </div>
                    </div>
                </div>
            </header> */}
        </div>
     );
}
 
export default Announce;