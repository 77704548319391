import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../config'
import { useAuthValue } from '../AuthenContext';
import { useNavigate } from 'react-router-dom';
import bgLogin from '../Asset/avatar/bglogin.webp'
import logo from '../Asset/Logo/logo_transparent.png'
import { onAuthStateChanged} from 'firebase/auth';
import './Login.css'

const Login = (props) => {
    const history = useNavigate()
    const [credential,setCredential] = useState({
        email: "",
        password:""
    })

    const [valid,setValid] = useState(true)
    const [error,setError] = useState({
        allFields : "",
        emailError: "",
        passwordError : ""
    })
    const [success,setSuccess] = useState({
        allFields : ""
    })
    const messages = {
        allfieldError : "All Fields should be Entered",
        successfullfield : "All input Enter",
        emailError : "Email is invalid", 
        passwordError : "password not enough"
    }
    const {currentUser} = useAuthValue
    // console.log('currentUser: ',currentUser)
   
    const handleChange = (e) => {
        const {name,value} = e.target
        // setCredential({...credential,[name]:value})
        let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; 
        let lengthPassword = 4; 
        if(name === 'email'){
            console.log('email is ',value); 
            if(value.match(pattern)){
                setError({"emailError" : ""})
                setCredential({...credential,[name]:value})
                setValid(true)
            }
            else{
                setError({"emailError" : messages.emailError})
                setValid(false)
            }
        }
        else if(name === 'password'){
            console.log('password is ',value); 
            if(value.length > lengthPassword ){
                console.log('length meet')
                setError({"emailError" : ""})
                setCredential({...credential,[name]:value})
                setValid(true)
            }
            else{
                console.log("length no met")
                setError({"passwordError" : messages.passwordError})
                setValid(false)
            }
        }
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        const email = credential.email;
        const password = credential.password
        if(email === "" || password === ""){
            setError({"allFields":messages.allfieldError})
        }
        else{
            setSuccess({"allFields":messages.successfullfield})
            signInWithEmailAndPassword(auth,email,password)
            .then((userCredential) => {
                history('/dashboard')
            })
            .catch((error) => {
                const errorCode = error.code; 
                const errorMessage = error.message; 
            });
        }
    }
    // console.log('credential login email: ',credential.email,' password: ',credential.password)
    return ( 
        <div>
            <div className="container col-xl-10 col-xxl-8 px-4 py-5">
                <div className="row align-items-center g-lg-5 py-5">
                    <div className="col-lg-7 text-center text-lg-start">
                        <h4 className="display-4 fw-bold lh-1 mb-3">Welcome to <br/>JR Hi-Tech.</h4>
                        <p className="col-lg-10 fs-4">
                        "We're thrilled to have you on board! Our cutting-edge technology and innovative solutions are designed to take your business to the next level. 
                        Explore our site to discover the latest advancements and expert insights that will propel your success. Let's shape the future together!"
                        </p>
                    </div>
                    <div className="col-md-10 mx-auto col-lg-5">
                        <form className="p-4 p-md-5 border rounded-3 bg-light" onSubmit={handleSubmit}>
                            <div className='text-center'><img src={logo} alt='' style={{width:'70px',height:'70px'}}/></div>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name='email' onChange={handleChange}/>
                                <label htmlFor="floatingInput">Email address <span style={{color:'red'}}>*</span></label>
                            </div>
                            {error.emailError && <div className='mt-1 mb-2 pb-2'><span style={{color:'red'}}>{error.emailError}</span></div>}
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="floatingPassword" placeholder="Password" name='password' onChange={handleChange}/>
                                <label htmlFor="floatingPassword">Password <span style={{color:'red'}}>*</span></label>
                            </div>
                            {error.passwordError && <div className='mt-1 mb-2 pb-2'><span style={{color:'red'}}>{error.passwordError}</span></div>}
                            {error.allFields && <div className='py-2'><span style={{color:'red'}}>{error.allFields}</span></div>}
                            {success.allFields && <div className='py-2'><span style={{color:'green'}}>{success.allFields}</span></div>}                           
                            <div className="checkbox mb-3">
                                <label>
                                <input type="checkbox" value="remember-me"/> Remember me
                                </label>
                            </div>
                            <div className='text-center'>
                                {valid ? <button className="btn btn-lg btn-primary" type="submit">Login</button> :
                                    <button className="btn btn-lg btn-primary" type="submit" disabled>Login</button>}
                            </div>
                            <hr className="my-4"/>
                            <small className="text-muted">By clicking Login, you agree to the terms of use.</small>
                        </form>
                </div>
                </div>
                
                
            </div>
        </div>
        // <div className='login' id="login" >
        //     <div className='container text-center'>
        //         <div>
        //            <img src={logo} alt='' style={{width:'150px',height:'150px'}}/>
        //         </div>
        //         <div className='py-2'>
        //             <h1>Please sign in</h1>
        //         </div>
        //         <div className='h-100 d-flex align-items-center justify-content-center py-3'>
        //             <form onSubmit={handleSubmit}>
        //                 <input type="email" className='form-control' name='email' placeholder='Email address' onChange={handleChange} required/>
        //                 <input type="password" className='form-control' name='password' placeholder='Password' onChange={handleChange} required/><br/>
        //                 <button className='btn btn-primary '>Login</button>
        //             </form>
        //         </div>
        //     </div>
            
        // </div>
     );
}
 
export default Login;