import React, {useState} from 'react'
import AddingRecords from '../utility/PSBMS/components/AddingRecords';

const Download = () => {

    const [cadAmount,setCadAmount] = useState(0)
    const [usdAmount,setUsdAmount] = useState(0)
    const [moneyFortmat,setMoneyFortmat] = useState()

    const handleChange = (e) => {
        const {name,value} = e.target; 
        setCadAmount(value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        var amountChange= cadAmount / 1.278
        setUsdAmount(amountChange) 
        const formattedNumber = amountChange.toLocaleString("en-US");
        setMoneyFortmat(formattedNumber)
    }

    const resetAll = () => {
        setCadAmount(0)
        handleSubmit()
    }
    // console.log("cad amount: ",cadAmount," usd amount: ",usdAmount)
    return ( 
        <div className='container'>
            <h3>Welcome to JR Currency Converter</h3><hr/>
            <h5>1 Canada Dollar equals to<br></br>0.78 $ USD Dollar.</h5>
            <form onSubmit={handleSubmit}>
            <div className="row g-3 align-items-center">
                <div className="col-auto">
                    <label htmlFor="name" className='col-form-label'>CAD: </label>
                </div>

                <div className="col-auto">
                    <input type="text" id='cadAmount' name='nom' className="form-control" placeholder='0' onChange={handleChange}/>
                </div>

                <div className="col-auto">
                    <span className="form-text" id='nom'> How much of CAD do you need ?</span>
                </div>
            </div>

            <div className="row g-3 align-items-center">
                <div className="col-auto">
                    <label htmlFor="name" className='col-form-label'>USD: </label>
                </div>

                <div className="col-auto">
                    <input type="text" id='name' name='nom' value={`$ `+moneyFortmat} className="form-control" />
                </div>

                <div className="col-auto">
                    <span className="form-text" id='nom'> How much of USD you will receive.</span>
                </div>
            </div>
            <button className="btn-primary ">Envoyer</button> 
            <button className='btn-success' onClick={resetAll}>Reset</button>
            </form>
            
           
           
        </div>
     );
}
 
export default Download;