import React, { useState, useEffect } from 'react';
import { doc, deleteDoc, collection, getDoc} from 'firebase/firestore';
import { useLocation,useNavigate } from 'react-router-dom';
import { db } from '../../../config';

const DeletingRecord = () => {
    const [recordID,setRecordID] = useState()
    // const gettingBudgetTbData = props.gettingBudgetTbData
    const navigate = useNavigate();
    const location = useLocation(); 
    const gettingBudgetTbData = location.state
    console.log('data budget -> ',gettingBudgetTbData)
    const handleChange = (e) => {
        setRecordID(e.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault(); 
        DeletingDocument()
        const timeout = setTimeout(() => {
            console.log('this will be called after 2 sec')
        },6000);
        clearTimeout(timeout);
        // console.log('finished')
        
       
    }

    const DeletingDocument = () => {
        const ref = doc(db,"budgetTable",filterBudgetTbDatas[0].key); 
        deleteDoc(ref)
        .then(() =>{
            console.log('deleted successfully')
        })
        .catch((error) => {
            console.log('unsuccessful operations')
        })
        // window.location.reload(false)
        navigate('/dashboard')
    }
    const filterBudgetTbDatas = gettingBudgetTbData.filter(obj => {
        return obj.expenseID === recordID
    })

    // useEffect(() => {
    //     window.location.reload(false)
    // },[])
    // console.log('filter: ',filterBudgetTbDatas[0])
    return ( 
    <div className='container py-3'>
        <table className='table table-bordered border-secondary'>
                    <thead>
                        <tr className='text-center'>
                            <th>ID</th>
                            <th>EXPENSES</th>
                            <th>AMOUNT</th>
                            <th>ALOC</th>
                        </tr>
                    </thead>
                
                    <tbody>
                        {
                            gettingBudgetTbData.map((expense) =>             
                                <tr className='text-center' key={expense.expenseID}>
                                    <td>{expense.expenseID}</td>
                                    <td>{expense.expensesName}</td>
                                    <td><strong>$</strong> {expense.amount}</td>
                                    <td><strong>$</strong> {expense.allocation}</td>
                                </tr>
                            )
                        }     
                    </tbody>
        </table>
        <h4>Deleting a Record</h4>
            <form onSubmit={handleSubmit}>
                <div className='row g-3 align-items-center'>
                   
                    <div className='col-auto'>
                        <input type='text' className='form-control' placeholder='Enter the record ID' onChange={handleChange}></input>
                    </div>
                </div><br/>
                <button className="btn-primary">Delete The Existing Record</button>
            </form>
        
    </div> );
}
 
export default DeletingRecord;