import React, { useState, useEffect } from 'react'; 
import firebase from 'firebase/app'; 
import { QuerySnapshot, addDoc, getDocs } from 'firebase/firestore';
import {db,storages} from '../config';
import { doc, deleteDoc,  collection, query, onSnapshot, orderBy, limit,serverTimestamp } from 'firebase/firestore';
import Container from 'react-bootstrap/Container'; 
import Row from 'react-bootstrap/Row'; 
import Col from 'react-bootstrap/Col'; 
import profile from '../Asset/avatar/user1.png';
import SendingMessage from './SendingMessage';
import { useAuthValue } from '../AuthenContext';
import { ref,getDownloadURL } from "firebase/storage";
// import { serverTimestamp} from 'firebase/firestore'; 
import DisplayName from './DisplayName';

const Channel = () => { 
    const [messages, setMessages] = useState([]); 
    const [newText, setNewText] = useState()
    const emptyArray = []
    const [userProfile,setUserProfile] = useState(emptyArray)
    const {currentUser} = useAuthValue()
    const userid = currentUser.uid
    const [getFilteredData,setGetFilteredData] = useState()
    const [userAvatar,setUserAvatar] = useState()

    const handleChange = (e) => {
        setNewText(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault(); 
        // if(currentUser.i)
        const dbref = addDoc(collection(db,"messages"),{
            text:newText,
            createdAt: serverTimestamp(),
            uid:currentUser.uid,
            displayName:getFilteredData.username
        })
        console.log("submit");
        setNewText("")
    }
    useEffect(() => {
        if(db) {
            const unsub = []
            const q = query(collection(db,"messages"),orderBy('createdAt'),limit(100)); 
            const result = onSnapshot(q,(querySnapshot) => {            
                const data = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setMessages(data)
            });
        }
    },[db]); 

    useEffect(() => {
        const dataArray = [] 
        const q = query(collection(db,"userProfile")); 
        const result = onSnapshot(q,(querysnapshot) =>{
            querysnapshot.forEach((doc) => {
                dataArray.push({
                    ...doc.data(), 
                    key: doc.id, 
                });
            });
            setUserProfile(dataArray)
        }); 
        return () => result();
    },[userid])

    useEffect(() => {

        const filterData = userProfile.filter((obj) => {
            return obj.userid === userid
        })
        // console.log('check this',filterData[0])
        setGetFilteredData(filterData[0])
    },[userProfile])
    // console.log('id: ',messages[1].text);
    // console.log("user: ",currentUser.uid);
    // console.log('user profile: ',userData);

    // const d = new Date(); 
    // let time = d.getTime(); 
    // // console.log("be carefull -> ",messages)
    // const e = 168956
    // const hoursDisplay = (e) => { 
    //     // const timestamp = new firebase.firestore.Timestamp(jsonTimestamp.e)
    //     const datestamp = e.toDate().toDateString()
    //     const timestamp = e.toDate().toLocaleTimeString('en-US')
    //     return (<span className='text-dark'>{datestamp}  {timestamp}</span>)
    // }

    // const ImageDisplay = (userId) => {
    //     var refLink;
    //     const dataImagefilter = userProfile.filter((elt) => {
    //         return elt.userid === userId
    //     })
    //     // console.log('verify this',dataImagefilter[0])
    //     refLink = dataImagefilter[0].avatar; 
    //     // useEffect(() => {
    //         if(refLink){
    //             getDownloadURL(ref(storages,refLink))
    //             .then((url) => {
    //                 setUserAvatar(url);
    //             })
    //         }
    //     // },[])
    //     return {userAvatar}
    // }

    // console.log('users ->',userProfile)
    return (
        <div className='bg-light'>
            {messages.map( (message) => (                
                    message.uid === currentUser.uid ? 
                    <Row key={message.id} className="container md-2 alignt-items-center rounded" >
                        <Col  className="colimage">
                            <Row className="justify-content-center">
                                {/* <img className="rounded-circle me-1 profile justify-content-end" alt="profile" width="50px" height="50px" src={profile} thumbnail="true" fluid="true"/> */}                                
                            </Row>
                        </Col>
                        <Col className='col-5'>
                            <Row className=" bg-primary text-light align-items-start  my-details rounded">                                
                                <p className='py-1'><strong><span style={{color:'orange'}}>{message.displayName}</span></strong><br/>{message.text}</p>                
                                <hr/>                                
                            </Row>
                            <p>  
                                {/* {hoursDisplay(message.createdAt)} */}
                                {/* {message.createdAt.toDate().toDateString()}  &nbsp; <strong>{message.createdAt.toDate().toLocaleTimeString('en-US')}</strong> */} 
                                {message.createdAt.toDate().toDateString() !==null ? <h1>Datetimestampss</h1> : <h1>Random</h1>}
                                {/* {1===2 ? good : not} */}
                                {/* Date Time */}
                                {/* <span className='d-flex justify-content-end'><img className='rounded-circle me-1' width="34" height="34" src={ImageDisplay(message.uid)} alt=""/></span> */}
                            </p>
                            <br/>
                        </Col>
                       
                    </Row>
                    : 
                    <Row key={message.id} className="py-2 container alignt-items-center rounded">
                        <Col className='col-5'>
                            <Row className="py-1 bg-dark text-light align-items-start p-1 my-details rounded">
                                <p className=''><strong><span style={{color:'green'}}>{message.displayName}</span></strong><br/>{message.text}</p>
                                {/* <p style={{textAlign:"left"}}>{message.text}</p> */}
                                <hr/>
                            </Row>
                            <p className='d-flex py-1 justify-content-end'> 
                                {/* {hoursDisplay(message.createdAt)}  */}
                                {/* {message.createdAt.toDate().toLocaleTimeString('en-US')} */}
                                {/* {message.createdAt.toDate().toDateString()}  &nbsp; <strong>{message.createdAt.toDate().toLocaleTimeString('en-US')}</strong> */}
                                {message.createdAt.toDate().toDateString() !==null ? <h1>Datetimestampss</h1> : <h1>Random</h1>}
                            </p>
                            {/* <span className='d-flex justify-content-start'><img className='rounded-circle me-1' width="34" height="34" src={ImageDisplay(message.uid)} alt=""/></span> */}
                        </Col>

                        <Col className="colimage">
                            <Row className="justify-content-center">
                                {/* <img className="profile justify-content-end" alt="profile" width="150px" height="350px" src={profile} thumbnail="true" fluid="true"/> */}
                                {/* 9:36 PM */}
                            </Row>
                        </Col>
                    </Row>
                ))
            }

            <div className='container'>
                {/* <div> */}
                    <div className='container'>
                        <hr/>
                        <form className="" onSubmit={handleSubmit} >
                            <textarea type="text" value={newText} className="form-control" id="message" cols="30" rows="4" placeholder="Message" name='message'  onChange={handleChange} required/>
                            <br/>
                            <div className='text-center'>
                                <button className="btn btn-lg btn-primary" type="submit" >Send</button>
                            </div>
                        </form>
                        
                    </div>
                {/* </div> */}
            </div>
            <br/>
        </div>
    );

};
export default Channel; 







// { messages.map((message) => (
//     message.uid === 'sssdf' ? 
//     <Row className="alignt-items-center rounded">
//         <Col  className="colimage">
//             <Row className="justify-content-center">
//                 <img className="profile justify-content-end" alt="profile" width="150px" height="350px" src="" thumbnail="true" fluid="true"/>
//             </Row>
//         </Col>

//         <col>
//             <Row className=" align-items-start p-2 my-details rounded">
//                 <p><strong>{message.displayName}</strong></p><br/><br/>
//                 <p style={{textAlign:"left"}}><strong>{message.displayName} :</strong> {message.text}</p>
//             </Row>
//         </col>

//     </Row> : 
//     <Row className="alignt-items-center rounded">
//         <col>
//             <Row className=" align-items-start p-2 my-details rounded">
//                 <p><strong>{message.displayName}</strong></p><br/><br/>
//                 <p style={{textAlign:"left"}}><strong>{message.displayName} :</strong> {message.text}</p>
//             </Row>
//         </col>

//         <Col className="colimage">
//             <Row className="justify-content-center">
//                 <img className="profile justify-content-end" alt="profile" width="150px" height="350px" src="" thumbnail="true" fluid="true"/>
//             </Row>
//         </Col>
//     </Row>

// ))
// }





// ------

// {messages.map(message => (
//     <div className='col-md-6'>
//         {message.text}
//     </div>
// ))}


{/* if user put on the right */}


// {/* else put on the left */}
// {messages.map(message => (
//     <div className='col-md-6 text-left'>
//         {message.text}
//     </div>
// ))}