import React, { useState, useEffect } from 'react';
import { db } from '../config';
import { collection, query, onSnapshot } from 'firebase/firestore';
import Info from './Info';
import { useNavigate } from 'react-router-dom';
const WelcomeUser = (props) => {
    const navigate = useNavigate()
    const userid = props.userid
    const [userName,setUserName] = useState()
    const [loading,setLoading] = useState(false)
    const [getFilteredData,setGetFilteredData] = useState()
    const setPlanf = props.planning
    const [flag,setFlag] = useState(false)
    // GETTING DATA FROM USERPROFILE 
    const emptyArray = []
    const [userProfile,setUserProfile] = useState(emptyArray)

    useEffect(() => {
        const dataArray = [] 
        const q = query(collection(db,"userProfile")); 
        const result = onSnapshot(q,(querysnapshot) =>{
            querysnapshot.forEach((doc) => {
                dataArray.push({
                    ...doc.data(), 
                    key: doc.id, 
                });
            });
            setUserProfile(dataArray)
        }); 
        return () => result();
    },[userid])
    // console.log('userProfile -> ',userProfile)
    useEffect(() => {
        // setLoading(true)
        // console.log('second')
        const filterData = userProfile.filter((obj) => {
            return obj.userid === userid
        })
        setGetFilteredData(filterData[0])
    },[userProfile])
   
    const handleSubmit = (event) => {
        console.log('submitted')
        event.preventDefault()
        navigate('/dashboard')
    }

    const openDivPlannification = () => {
        console.log('open new div and change the state')
        setFlag(!flag)
    }
    useEffect (() => {
        setPlanf(flag)
    },[flag])

    const monthlyGoal = () => {
        navigate('/psbms/monthlygoal')
    }
    return ( 
        <div>
            {/* <Info dataInfo={userProfile}/> */}
            {/* <h1>Welcome {getFilteredData.username ? <span>{userName}</span> : <span>username</span>}</h1> */}
            
            <div className="">
                <header className="pb-2 mb-1 border-bottom">
                <a href="/profile" className="d-flex align-items-center text-dark text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" className="me-2" viewBox="0 0 118 94" role="img"><title>Bootstrap</title><path fillRule="evenodd" clipRule="evenodd" d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z" fill="currentColor"></path></svg>
                    <span className="fs-4">Welcome {getFilteredData ? <span style={{color:'red'}}>{getFilteredData.username}</span> : <span>username</span>}</span>
                </a>
                </header>

                <div className=" mb-3 bg-dark text-light rounded-3">
                    <div className="container py-4">
                        <h1 className="display-5 fw-bold">Personal Small Business Management System</h1>
                        <p className="col-md-8 fs-4">This system will help to manage your incoming money and controlling your expenses so that you can save a lot. The system has multiple table that will help you out</p>
                        <button className="btn btn-primary btn-lg" type="submit" onClick={handleSubmit}>Dashboard</button> &nbsp;
                        <button className="btn btn-success btn-lg" type="submit" onClick={openDivPlannification}>Plannification</button> &nbsp;
                        <button className="btn btn-danger btn-lg" type="submit" onClick={monthlyGoal}> Monthly Goal</button>
                    </div>
                </div>
            </div>

            
           
        </div>
     );
}
 
export default WelcomeUser;