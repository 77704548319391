import React , {useState} from 'react';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FaFacebookSquare} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'
import {ImYoutube2} from 'react-icons/im'
import { useEffect } from 'react';
import { addDoc, collection, query} from 'firebase/firestore';
import { db } from '../config';
import { onSnapshot } from 'firebase/firestore';
import { clear } from '@testing-library/user-event/dist/clear';
import emailjs from '@emailjs/browser'; 
const Footer = () => {
    const date = new Date(); 
    const year = date.getFullYear(); 
    const day = new Date().toLocaleDateString('en-us',{weekday:'long'});
    const hour = new Date().toLocaleDateString('en-us',{hour:'2-digit',minute:'2-digit'});
    const [email,setEmails]= useState("");
    const [listEmail,setListEmail] = useState("")
    const [isValid,setValid] = useState(false); 
    const [error,setError] = useState(null)
    const [success,setSuccess] = useState(null)

    const sendEmail = () => {
        emailjs.send("service_3tohxzo","subscription_conf",{
            customerEmail: email
        },"WLKO_R3MXbzorLJh3")
        .then((result) => {
            console.log(result.text);
        },(error) => {
            console.log(error.text);
        });
        setEmails('');
    }
    const handleClicked = (e) => {
        e.preventDefault();
        var found = false
        if(listEmail.length === 0 && isValid == true ){
            setSuccess("The email ID subscription done successfully");
            setError()
            const dbref = addDoc(collection(db,"subscribedemails"),{email:email})
            sendEmail();
        }
        else{
            for(var i=0; i < listEmail.length; i++){
                if(listEmail[i].email == email){
                    setError("The email ID already exist")
                    setSuccess()
                    found = true;
                    break;
                }
            }
            if(found == false && isValid == true ){
                setError()
                setSuccess("The email ID subscription done successfully")
                const dbref = addDoc(collection(db,"subscribedemails"),{email:email})
                sendEmail();
            }
        }
    }

    useEffect(() => {
        var dataArray = []
        const q = query(collection(db,"subscribedemails")); 
        const result = onSnapshot(q,(querysnapshot) => {
            querysnapshot.forEach((doc) => {
                dataArray.push({
                    ...doc.data(),
                    key: doc.id,
                });
            });
            setListEmail(dataArray)
            dataArray = [];
        });
        return () => result(); 
    },[email])

    const handleChange = (event) => {
        // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let re =  /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
        if(re.test(event.target.value)){
            setEmails(event.target.value); 
            setValid(true)
        }
        else{
            setError("Invalid email, maybe show an error to the user");
            setSuccess()
        }
    }
    return ( 
       
        <div className='container-fluid bg-dark'>
            <div className="container">
                <footer className="py-1">
                <ul className='nav justify-content-center pb-1 mb-2'>
                    <li className='nav-item'><a href="/e-store" className='nav-link px-1 text-muted active area-curent="page" '>Stores</a></li>
                    <li className='nav-item'><a href="/porfolio" className='nav-link px-1 text-muted'>Porfolio</a></li>
                    {/* <li className='nav-item'><a href="/testimonies" className='nav-link px-1 text-muted'>Testimonies</a></li> */}
                    <li className="nav-item"><a href="/FAQS" className='nav-link px-1 text-muted'>FAQS</a></li>
                    <li className='nav-item'><a href="/bootcamp" className="nav-link px-1 text-muted">BootCamp</a></li>
                </ul>
                <div className="d-flex text-center justify-content-center border-bottom mb-md-0 mb-4">
                    <p md="8" className='text-white px-5'>
                        "JR Hi-Tech LLC is a cutting-edge web design and development company, crafting innovative digital experiences that drive business success and exceed customer expectations."
                    </p>        
                </div>
                    <div className="row py-3">
                        <div className="col-6 col-md-2 mb-3">
                            <h5 className='text-white'>JR Tech</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="/carrers" className="nav-link p-0 text-muted">Carrers</a></li>
                                <li className="nav-item mb-2"><a href="/jrcontrat" className="nav-link p-0 text-muted">Contrat</a></li>                                
                                <li className="nav-item mb-2"><a href="/contactus" className="nav-link p-0 text-muted">Contact Us</a></li>
                                <li className="nav-item mb-2"><a href="/consultation" className="nav-link p-0 text-muted">Consultation</a></li>                                
                                <li className="nav-item mb-2"><a href="/previousprojet" className="nav-link p-0 text-muted">Previous Projet</a></li>                                                                
                            </ul>
                        </div>
                        <div className="col-6 col-md-2 mb-3">
                            <h5 className='text-white'>JSProd</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="/contactjsprod" className="nav-link p-0 text-muted">Contact Us</a></li>
                                <li className="nav-item mb-2"><a href="/audiostudio" className="nav-link p-0 text-muted">Audio Studio</a></li>
                                <li className="nav-item mb-2"><a href="/videostudio" className="nav-link p-0 text-muted">Video Studio</a></li>
                                <li className="nav-item mb-2"><a href="/livestreamstudio" className="nav-link p-0 text-muted">LiveStream Studio</a></li>
                                <li className="nav-item mb-2"><a href="/concertrecording" className="nav-link p-0 text-muted">Concert Recording</a></li>                            
                            </ul>
                        </div>
                        <div className="col-6 col-md-2 mb-3">
                            <h5 className='text-white'>JR Auto</h5>
                            <ul className="nav flex-column">                                
                                <li className="nav-item mb-2"><a href="/rentacar" className="nav-link p-0 text-muted">Rent a Car</a></li>
                                <li className="nav-item mb-2"><a href="/requestride" className="nav-link p-0 text-muted">Request a Ride</a></li>                                                                
                                <li className="nav-item mb-2"><a href="/requestdriver" className="nav-link p-0 text-muted">Request a Driver</a></li>
                                <li className="nav-item mb-2"><a href="/carwashservice" className="nav-link p-0 text-muted">Car Wash Service</a></li>
                                <li className="nav-item mb-2"><a href="/buyorleasecar" className="nav-link p-0 text-muted">Buy or Lease a Car</a></li>                                
                            </ul>
                        </div>

                        <div className="col-6 col-md-2 mb-3">
                            <h5 className='text-white'>JR Homes</h5>
                            <ul className="nav flex-column">                           
                                <li className="nav-item mb-2"><a href="/faqs" className="nav-link p-0 text-muted">FAQs</a></li>
                                <li className="nav-item mb-2"><a href="/renthouse" className="nav-link p-0 text-muted">Rent a House</a></li>
                                <li className="nav-item mb-2"><a href="/buyhouse" className="nav-link p-0 text-muted">Buy a House</a></li>                                
                                <li className="nav-item mb-2"><a href="/getappartment" className="nav-link p-0 text-muted">Get Appartment</a></li>
                                <li className="nav-item mb-2"><a href="/jrhome/applynow" className="nav-link p-0 text-muted">Apply Now</a></li>
                            </ul>
                        </div>

                        <div className="col-md-4 offset-md-0 mb-3">
                            <form>
                            <h5 className='text-white'>Subscribe to our newsletter</h5>
                            <p className='text-white'>"Stay ahead of the curve with the latest insights and updates from JR Hi-Tech LLC! Subscribe to our newsletter now and receive</p>
                            <div className="d-flex flex-column flex-sm-row gap-2">
                                <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                                <input id="newsletter1" type="email" className="form-control" placeholder="Email address" onChange={handleChange}/>
                                <button className="btn btn-primary" type="button" value={email} onClick={handleClicked} >Subscribe</button>
                            </div>
                            {error && <span style={{color:'red'}}>{error}</span>}
                            {success && <span style={{color:'green'}}>{success}</span>}        
                            </form>
                            <br/>
                            <button className="btn btn-danger" type="button">Apply Now</button>
                        </div>
                    </div>
                    <div>
                        <p className='text-center text-info '><strong>Have a fantastic {day} !</strong></p>
                        {/* <button className="btn btn-primary" type="button">Apply Now</button>
                        <button className="btn btn-primary" type="button">Apply Now</button>
                        <p className='text-center text-info'> it's {hour}</p>
                        <hr className='bg-white clearfix '/> */}
                    </div>
                    <div className="d-flex flex-column flex-sm-row justify-content-between py-1 my-2 border-top text-light">
                    
                        <p>&copy; {year} JR Hi-Tech LLC. © All rights reserved. Powered by <a href="/aboutus" target="_blank" className=" text-danger text-decoration-none"><strong>  JRDev</strong></a></p> 
                        <ul className="list-unstyled d-flex">
                        {/* href="https://www.facebook.com/profile.php?id=100088349493903&mibextid=LQQJ4d" */}
                            <li className="ms-3"><a className="link-dark" href=""><FaFacebookSquare style={{color:'white',fontSize:'25px'}}/>&nbsp;</a></li>
                            <li className="ms-3"><a className="link-dark" href="https://www.instagram.com/invites/contact/?i=3gebyscvguh&utm_content=oj42kje"><BsInstagram style={{color:'white',fontSize:'25px'}}/></a></li>
                            <li className="ms-3"><a className="link-dark" href="https://www.youtube.com/channel/UCFd_KriXZ0tzVnKyiitc9GA"><ImYoutube2 style={{color:'white',fontSize:'35px'}}/></a></li>
                        </ul>
                    </div>
                </footer>
            </div>
        </div>
     );
}
export default Footer;