/* 
    Update made on Dec 21, 2023 - version JR_Hitech_v1_02
    I changed the user avatar format. 
    working on collapse navbar to stay.     
*/
import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar'; 
import { Link, NavLink } from 'react-router-dom';
// import Logo from '../Asset/Logo/logo_transparent.png'
import Logo from '../Asset/Logo/logo.png'
import user from '../Asset/avatar/user3.png'
import DashBoard from './../Dashboard';
import { db } from '../config';
import { useAuthValue } from '../AuthenContext';
import { useEffect } from 'react';
import { auth, storages} from '../config';
import { signOut } from 'firebase/auth';
import { ref,getDownloadURL } from "firebase/storage";
// import bootstrap from "Bootstrap"

const Header = filteredUser => {
    const {currentUser} = useAuthValue()
    const [imageProfile,setImageProfile] = useState();
    var refLink;
    var avatar;

    // const navLinks = document.querySelectorAll('.nav-item')
    // const menuToggle = document.getElementById('navbarSupportedContent')
    // const bsCollapse = bootstrap.Collapse.getOrCreateInstance(menuToggle, {toggle: false})
    //     navLinks.forEach((l) => {
    //         l.addEventListener('click', () => { bsCollapse.toggle() })
    //     })

    if(filteredUser.filteredUser===null){
        avatar = "noavalaible"
    }
    else{
        refLink = filteredUser.filteredUser[0].avatar;
    }
    useEffect(() => {
        if(refLink){
            getDownloadURL(ref(storages,refLink))
            .then((url) => {
                setImageProfile(url);
            })
        }
    },[])
    return ( 
        <div className='shadow sticky-top'>
            <div className='container-fluid bg-white sticky-top' >
                <nav className='navbar navbar-expand-lg navbar-dark bg-white' >
                    <div className="container" >
                    <div className='dropdown px-2 my-1 py-1text-end'>
                                    {/* <Avatar  alt="user" src={imageProfile}/> */}
                                    <a href='#' className=' d-block link-info text-decoration-none dropdown-toggle' id='dropdownUser1' data-bs-toggle='dropdown' aria-expanded='false'>
                                        {/* {imageProfile ? <img src={imageProfile} alt='mdo' width="55" height="55" className='rounded-circle'/> : 
                                        <img src={user} alt='mdo' width="55" height="55" className='rounded-circle'/> } */}
                                        {
                                            imageProfile && <img src={imageProfile} alt='mdo' width="50" height="45" className='shadow rounded-circle'/>
                                            // imageProfile && <Avatar  alt="user" src={imageProfile} sx={{ width: 50, height: 45 }}/>
                                        }
                                        {
                                            !imageProfile && <img src={user} alt='mdo' width="48" height="48" className='shadow rounded-circle'/> 
                                            // !imageProfile && <Avatar alt="user" src={user} sx={{ width: 55 , height: 55 }} />
                                        }                        
                                    </a>
                                    <ul className="dropdown-menu text-small " aria-labelledby="dropdownUser1">
                                        <li><a className="dropdown-item" href="/dashboard">Hi! { currentUser ? currentUser.email : 'user'}</a></li>
                                        <li><hr className="dropdown-divider"/></li>
                                        <li><a className="dropdown-item" href="/profile">Profile</a></li>
                                        <li><a className="dropdown-item" href="/dashboard">DashBoard</a></li>
                                        <li><a className="dropdown-item" href="/mycourses">My courses</a></li>
                                        <li><a className="dropdown-item" href="/psbms">Psbms</a></li>
                                        <li><a className="dropdown-item" href="/settings">Settings</a></li>
                                        
                                        <li><hr className="dropdown-divider"/></li>
                                    
                                        { currentUser ? <li><a className="dropdown-item" href="/home" onClick={() => signOut(auth)}>Sign Out</a></li> : 
                                        <li><a className="dropdown-item" href="/signin" >Sign In</a></li> }
                                        {currentUser == null && <li><a className="dropdown-item" href="/signup">Sign Up</a></li>}
                                    </ul>
                            </div>
                        <a href="/" className="navbar-brand d-flex align-items-center col-md-1 mb-1 mb-md-0 text-info text-decoration-none">
                            {/* <img src={Logo}
                                width="50%"
                                height="50%"
                                className="d-inline-block align-top rounded"
                                alt="JR LOGO"
                            /> */}
                            JR Hi-TECH 
                        </a>
                        <button 
                            className="navbar-toggler bg-info" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#navbarSupportedContent" 
                            aria-controls="navbarSupportedContent" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="navbar-nav col-12 col-md-8 mb-2 justify-content-center mb-md-0">
                                <NavLink  className="nav-item nav-link px-2 text text-dark fw-bold " aria-current="page" to="/home" >Home</NavLink>
                                <NavLink  className="nav-item nav-link px-2  text-dark fw-bold" to="/aboutus">About us</NavLink>
                                <NavLink  className="nav-item nav-link px-2  text-dark fw-bold" to="/services" >Services</NavLink>
                                <NavLink  className="nav-item nav-link px-2  text-dark fw-bold" to="/project" >Apps</NavLink>                                   
                                <NavLink  className="nav-item nav-link px-2  text-dark fw-bold" to="/weather" >Weather</NavLink>
                                <NavLink  className="nav-item nav-link px-2  text-dark fw-bold" to="/download">Download</NavLink>
                                <NavLink  className="nav-item nav-link px-2  text-dark fw-bold" to="/blog" >Blog</NavLink>
                            </div>
                            <form className='col-12 col-lg-auto mb-3 mb-lg-0 me-lg-2 bg-dark  rounded'>
                                <input type="search" className='form-control rounded text-danger' placeholder='Search...' aria-label='Search'/>    
                            </form>
                        </div>

                    </div>
                </nav>

            </div>
        </div>
     );
}

Header.defaultProps = {
    filteredUser : 'null'
}

export default Header;