import React from 'react'
import CustomersRev from '../component/CustomersRev';
import Field from '../component/Field';
import Goal from '../component/Goal';
import SlideShow from '../component/Slideshow';
import WeatherApi from '../component/WeatherApi';
import Reviews from '../utility/reviews';
import { useNavigate } from 'react-router-dom';
import './home.css'
import TypeMessage from '../component/TypeMessage';
import { useAuthValue } from '../AuthenContext';
import Channel from '../features/Channel';

const Home = () => {
    const {currentUser} = useAuthValue();
    const navigate = useNavigate()
    const registerCustomer = () => {
        navigate('/signup')
    }
    return ( 
        <div className=''>
            <SlideShow/>
            <Goal/>
            <div className='bg-dark text-light'>
                <Field/>
            </div>
            <div className='container py-2'>
                <div className="px-4 pt-3 my-1 text-center border-bottom">
                    <h1 className="display-4 fw-bold">Customer</h1>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            "Unlock the Future of Tech! Join the JR Hi-tech community and register now! Discover a world of cutting-edge innovation, 
                            expert insights, and exclusive benefits: Get special offers and updates Take the first step towards a brighter tech future!"
                        </p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                            { currentUser ? <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3" onClick={registerCustomer} disabled>Register</button> 
                            :
                            <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3" onClick={registerCustomer}>Register</button>
                            }
                            {/* <button type="button" className="btn btn-outline-secondary btn-lg px-4">Connect to Us</button> */}
                        </div>
                    </div>
                    {/* <div className="overflow-hidden" >
                        <div className="container px-5">
                            <img src="bootstrap-docs.png" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy"/>
                        </div>
                    </div> */}
                </div>
            </div>

           

          {/* <div className=' bg-dark'>
            <div className='container py-2'>
                <CustomersRev/>
            </div>
          </div> */}
          
            {/* reviews jo<br/> */}

        </div>
       
     );
}
 
export default Home;

