import React, {useState} from 'react'
import Container from 'react-bootstrap/Container'; 
import Row from 'react-bootstrap/Row'; 
import Col from 'react-bootstrap/Col'; 
import Button from 'react-bootstrap/Button'; 
// images
import Profile from '../Asset/avatar/user1.png'
import Profile2 from '../Asset/avatar/user2.png'
import Profile3 from '../Asset/avatar/user3.png'
import Profile4 from '../Asset/avatar/user4.png'
import CEO from '../Asset/avatar/CEO.JPG'; 
import GM from '../Asset/avatar/ruth.JPG'; 
import Tech from '../Asset/avatar/tech.jpg';
import MarkDir from '../Asset/avatar/markdir.png'
import '../pages/aboutus.css';

const AboutUS = () => {
    const [myteam,setMyteam] = useState([
        {
            name : 'Janet Teho',
            position : 'C.E.O/Founder', 
            description : 'is a multifaceted entrepreneur and innovator, serving as the CEO of JR Hi-Tech. With a passion for storytelling'+
            'Jean is also a novelist, weaving tales that captivate and inspire. As a computer scientist,'+
            'he brings a deep understanding of technology to his leadership, driving innovation and excellence at JR Hi-Tech. With 5 years of experience in web development,'+ 
            'Jean has honed his skills in building and delivering cutting-edge digital solutions. Under his guidance, JR Hi-Tech is revolutionizing the tech industry,'+
            'harnessing creativity and expertise to drive success. Jeans unique blend of artistry, technical prowess, and leadership is fueling a new era of '+
            'innovation and growth',
            photoImage : Profile4, 
            side: 'left'
        },
        {
            name : 'Sarah Matego',
            position : 'General Manager', 
            description : "is a seasoned executive with 15 years of experience as a manager, now serving as General Manager at JR HiTech." +
            "With a proven track record of leadership and a passion for innovation, Janine has dedicated her career to driving "+
            " success in the tech industry. Her extensive expertise spans management, strategy, and team building, making her a valuable asset to "+
            " the JR HiTech team. As General Manager, Janine is responsible for overseeing day-to-day operations, leading the company's"+
            "growth strategy, and fostering a culture of excellence. Her dedication, expertise, and collaborative approach have earned"+
            "her a reputation as a respected leader in the industry.", 
            photoImage: Profile3,
            side:'right'
        },
        {
            name : 'Nathan N Asani', 
            position : 'Marketing Director', 
            description : "Nathan N Asani is a rising star in the marketing world, recently joining JR HiTech as Marketing Director." +
            "With a fresh perspective and a passion for innovation, Nathan is eager to make his mark in the industry."+
            "As a new professional, Nathan brings a unique energy and creativity to the team, leveraging his skills and "+
            "knowledge to drive marketing success for JR HiTech. With a strong desire to learn and grow, Nathan is excited"+
            " to contribute to the company's mission and vision, and to explore the dynamic world of tech marketing.",
            photoImage: MarkDir, 
            side:'left'
        },
        {
            name : 'Jocelyn Zaparo', 
            position : 'Technology Director', 
            description :  "Jocelyn Zaparo is a talented technology leader, currently serving as Technology Director at JR HiTech. Her journey with"+
            "the company began in 2019 as an intern, where she quickly demonstrated her aptitude and enthusiasm for innovation."+
            "Through hard work and dedication, Jocelyn rapidly advanced to her current role, overseeing the development and "+
            "implementation of cutting-edge technologies. With a passion for staying ahead of the curve, Jocelyn drives "+
            "technological advancements and solutions that propel JR HiTech forward. Her expertise, combined with her"+
            "collaborative spirit, make her an invaluable asset to the team, shaping the future of the company and the"+
            " tech industry as a whole.",
            photoImage: Profile4, 
            side:'right'
        }, 
        {
            name : 'Pablo Kansula', 
            position : 'HR Officials', 
            description : "Pablo Kandula is a seasoned Human Resources professional, dedicated to fostering a supportive and inclusive work environment"+
            "at JR HiTech. With a passion for people and a commitment to excellence, Pablo serves as a trusted advisor and strategic partner"+
            "to the leadership team. With expertise spanning talent acquisition, employee development, and benefits management, Pablo is"+
            "instrumental in building and maintaining a high-performing team. His door is always open to listen, advise, and support the"+
            "needs of JR HiTech's most valuable asset - its employees. Pablo's dedication to creating a positive work culture and employee"+
            "experience has earned him a reputation as a respected and approachable HR leader.",
            photoImage: Profile3, 
            side:'left'
        }
    ])
    return ( 
        <div>
        <div className='bg-info' > 
        {/* style={{height:'100px'}} */}
            <h1 className='text-center text-white py-5 shadow' >About JR Hi-Tech </h1>
        </div>
        <div className='container  py-4'>
            <h3>Who we are</h3>
            <p style={{fontSize:'18px'}}>
                "At JR Hi-Tech, we, <strong>JR Hi-Tech LLC</strong> are a team of passionate computer scientists, software 
                engineers, and IT professionals dedicated to helping businesses thrive in the digital age. 
                With our combined expertise and commitment to innovation, we empower organizations to harness the power of 
                technology and achieve their full potential. Together, we strive to create a brighter 
                future where technology and business converge to drive growth, success, and positive impact."
            </p>

            <h3>Our Story</h3>
            <p style={{fontSize:'18px'}}>
                "In 2015, a group of passionate designers and installers came together to form JR Hi-Tech, with a shared 
                vision of revolutionizing the flyer design and installation industry. For years, we honed our skills, pushing 
                the boundaries of what was possible with print marketing. But as the world evolved and technology advanced, 
                we knew it was time to expand our horizons. In 2020, we took a bold leap into the digital realm, introducing
                web design and development to our repertoire. With our roots in print and our sights set on the future, we 
                merged our expertise to create a powerhouse of creativity and innovation. Today, JR Hi-Tech is a one-stop-shop
                for businesses looking to make a lasting impact, both online and offline. Join us on our journey to shape the 
                future of marketing and design."
            </p>
            <h3>Our Mission</h3>
            <p style={{fontSize:'18px'}}>
                Our Mission is to empower businesses to thrive in the digital age by seamlessly integrating cuting-edge 
                technology into their operations, enhancing effiency, innovation, and growth. 
            </p>
        </div>

        <div className='bg-light py-1'>
            <h3 className="align-items-center" style={{color:'red'}}><strong><center>The JR Hi-Tech Family</center></strong></h3>
            <div className='container'>
                <div id="about">
                    <div className="about">
                    <Container>
                        {
                            myteam.map((team) => 
                                team.side === 'left' ? 
                            <Row key={team.name} className="container pt-1 pb-1 bg-dark text-white align-items-center rounded">
                                <Col xs={12} md={4} className="colimage">
                                    <Row className="justify-content-center">
                                        <img className="profile justify-content-end" alt="profile" width="100px" height="200px" src={team.photoImage} thumbnail="true" fluid="true"/>
                                    </Row>
                                </Col>
                                <Col xs={12} md={8}>
                                    <Row className=" align-items-start p-2 my-details rounded">
                                        <p>Hi There! I am &nbsp;<strong>{team.name}</strong></p>
                                        <br/><p style={{color:'red'}}><strong>A {team.position} of JR Hi-Tech, LLC</strong></p><br/>
                                            <p style={{textAlign:"left"}}><strong>{team.name}</strong> {team.description}
                                            </p>
                                        <br/><br/>
                                        <Col className="d-flex justify-content-center flex-wrap">
                                            <div>
                                                <a href="/contactUs">
                                                    <Button className="m-2" variant="outline-primary">Contact Me</Button>
                                                </a>
                                            </div>
                                            <div>
                                                <a href="/book">
                                                    <Button className="m-2" variant="outline-success">Book Me</Button>
                                                </a>
                                            </div>
                                            <div>
                                                <a href="/services">
                                                    <Button className="m-2" variant="outline-danger">My Services</Button>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> : 
                             <Row key={team.name} className="pt-3 pb-5 bg-light shadow text-dark align-items-center rounded">
                                <Col xs={12} md={8}>
                                    <Row className=" align-items-start p-2 my-details rounded">
                                        <p>Hi There! I am &nbsp;<strong>{team.name}</strong></p>
                                        <br/><p style={{color:'red'}}><strong>A {team.position} of JR Hi-Tech, LLC</strong></p><br/>
                                            <p style={{textAlign:"left"}}><strong>{team.name}</strong> {team.description}
                                            </p>
                                        <br/><br/>
                                        <Col className="d-flex justify-content-center flex-wrap">
                                            <div>
                                                <a href="/contactUs">
                                                    <Button className="m-2" variant="outline-primary">Contact Me</Button>
                                                </a>
                                            </div>
                                            <div>
                                                <a href="/book">
                                                    <Button className="m-2" variant="outline-success">Book Me</Button>
                                                </a>
                                            </div>
                                            <div>
                                                <a href="/services">
                                                    <Button className="m-2" variant="outline-danger">My Services</Button>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={4} className="colimage">
                                    <Row className="justify-content-center">
                                        <img className="profile justify-content-end" alt="profile" width="150px" height="350px" src={team.photoImage} thumbnail="true" fluid="true"/>
                                    </Row>
                                </Col>
                         </Row>
                        )}
                    </Container>
                    </div>
                </div>
            </div>
        </div>

    </div>
    );
}
 
export default AboutUS;