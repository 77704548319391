import React, { useState, useEffect } from 'react';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db} from '../config';
import UserPicked from './UserPicked';
import { useAuthValue } from '../AuthenContext';
import AboutUS from './../pages/Aboutus';

const GetUserProfile = () => {
    const [userData, setUserData] = useState(null); 
    const { currentUser } = useAuthValue()
    useEffect(() => {
        if(currentUser){
            const userArray = []
            const q = query(collection(db,"userProfile")); 
            const result = onSnapshot(q, (querysnapshot) => {
                querysnapshot.forEach((doc) => {
                    userArray.push({
                        ...doc.data(), 
                        key: doc.id, 
                    }); 
                }); 
                setUserData(userArray)
            }); 
            return () => result() 
        }
        else{
            const currentUserNoExisted = "currentUser is not here"
            // console.log('currentUser is not here')
        }
    },[])
    return ( 
        <div>
            {userData &&  <UserPicked userData={userData}/>}
            {userData === null &&  <UserPicked userData={null}/>}
        </div>
     );
}
 
export default GetUserProfile;