import { Navigate, Outlet } from "react-router-dom";
import { useAuthValue } from "./AuthenContext";

const PrivateRoute = () => {
    const {currentUser} = useAuthValue()
    // console.log('private route',currentUser)
    return ( 
        currentUser?  <Outlet/> : <Navigate to={"/signin"}/>
     );
}
 
export default PrivateRoute;