import React, {useState, useEffect} from 'react'
// import '../../StyleFiles/Table/PersonalBusiness.css'
import AddingRecords from './components/AddingRecords'
import { useAuthValue } from '../../AuthenContext';
import DeletingRecord from './components/DeletingRecord';
import WelcomeUser from '../../Users/WelcomeUser';
import { useNavigate } from 'react-router-dom';
import { doc, deleteDoc,  collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../../config';
import GoalMonthly from './components/GoalMonthly';


const PersonalBusiness = (props) => {
    const {currentUser} = useAuthValue()
    // console.log('currentUser',currentUser)
    const [addingRecord,setAddingRecord] = useState("false"); 
    const [deletingRecord,setDeletingRecord] = useState("false"); 
    const [totalAmount,setTotalAmount] = useState()
    const [totalAllocation,setTotalAllocation] = useState()
    // console.log('data get from backend ->',props.dataSet)
    const [gettingBudgetTbData,setGettingBudgetTbData] = useState(props.dataSet)
    // const [lengthData,setLengthData] = useState()
    const navigate = useNavigate()
    const [planning, setPlanning ] = useState(false)
    // 
    const [loading,setLoading] = useState(false)
    const [dataset,setDataSet] = useState()
    // 

    const getTotalAmount = () =>{
        var total = 0
        var allocTtl = 0
        let count = 0 
        filterBudgetTbData.map((data) => {
            // if(data.user === currentUser.uid){
            total += Number(data.amount);
            allocTtl += Number(data.allocation)}
                // setLengthData(count)
                // count +=1
            // }
        )
        setTotalAmount(total)
        setTotalAllocation(allocTtl)
    }
  
    const AddingNewRecord = ( ) => {
        setAddingRecord("true")
        setDeletingRecord('false')
        navigate('addingRecords', {state:filterBudgetTbData})
    }
    const DeleteRecord = ( ) => {
        setDeletingRecord("true")
        setAddingRecord("false")
        navigate('deletingRecords', {state:filterBudgetTbData})
    }

    const Alldeletion = ( ) => {
        navigate('')
        filterBudgetTbData.map((item) => {
            const ref = doc(db,"budgetTable", item.key); 
            deleteDoc(ref)
            .then(() => {
                console.log('deleted successfully')
            })
            .catch((error) => {
                console.log('unsuccessfull operations')
            })
        //    window.location.reload(false)
           navigate('/dashboard')
        })
    }
    const Reset = ( ) => {
        setDeletingRecord("false")
        setAddingRecord("false")
        window.location.reload(false)
    }

    // const monthData = () => {
    //     const dataArray = []
    //     const q = query(collection(db,"monthlygoal")); 
    //     const result = onSnapshot(q,(querysnapshot) =>{
    //         querysnapshot.forEach((doc) =>{
    //             dataArray.push({
    //                 ...doc.data(),
    //                 key: doc.id,
    //             });
    //         });
    //         setDataSet(dataArray)
    //         setLoading(true)
    //     });
    //     return () => result();
    // }

    // console.log('adding->',addingRecord,'deleting->',deletingRecord)
    // console.log('type of uid', typeof currentUser.uid)

    useEffect(()=>{
        getTotalAmount()
    },[gettingBudgetTbData])
  
    // const filterBudgetTbData = Object.keys(gettingBudgetTbData)
    //     .filter((key) => key.includes(currentUser.uid))
    //     .reduce((obj,key) => {
    //         return Object.assign(obj, {
    //             [key]:user[key]
    //         });
    //     },{});

    const filterBudgetTbData = gettingBudgetTbData.filter(obj => {
        return obj.userid === currentUser.uid
    })

   
    useEffect(() => {
        const dataArray = []
        const q = query(collection(db,"monthlygoal")); 
        const result = onSnapshot(q,(querysnapshot) =>{
            querysnapshot.forEach((doc) =>{
                dataArray.push({
                    ...doc.data(),
                    key: doc.id,
                });
            });
            setDataSet(dataArray)
            setLoading(true)
        });
        return () => result();
    },[])
    // console.log('filter data ->',filterBudgetTbData)
    // console.log('plan:',planning)

  

    // console.log('month data -> ',filterMonthData)

    return ( 
        <div className='container py-2'>
            {/* <h1>Welcome {currentUser.email}</h1> */}
            <div><WelcomeUser userid= {currentUser.uid} planning = {setPlanning} /></div>
            <div>
                {
                    planning ? 
                        <div>
                            <GoalMonthly dataSet = {dataset} />
                        </div> 
                        : 
                        <div></div>
                }
            </div>
            <h3>Budget Table</h3>
            <div className='table-responsive-sm'>   
                {/* <h1>{currentUser.uid}</h1> */}
                {/* { ge} */}
                <table className='table table-bordered border-secondary'>
                    <thead>
                        <tr className='text-center'>
                            <th>ID</th>
                            <th>EXPENSES</th>
                            <th>AMOUNT</th>
                            <th>ALOC</th>
                            {/* <th>ACT</th> */}
                        </tr>
                    </thead>
                
                    <tbody>
                        {
                            filterBudgetTbData.map((expense) =>             
                                <tr className='text-center' key={expense.expenseID}>
                                    <td>{expense.expenseID}</td>
                                    <td>{expense.expensesName}</td>
                                    <td><strong>$</strong> {expense.amount}</td>
                                    <td><strong>$</strong> {expense.allocation}</td>
                                    {/* <td style={{width:'5px'}}><strong>update</strong></td> */}
                                </tr>
                            )
                        }                       
                         <tr className='bg-dark text-white fw-bold text-center'>
                            <td>Total</td>
                            <td>{filterBudgetTbData.length}</td>
                            <td><strong>$</strong> {totalAmount}</td>
                            <td><strong>$</strong> {totalAllocation}</td>
                            {/* <td><strong></strong></td> */}
                        </tr>
                    </tbody>
                </table>
            </div>        
            <br/>
            {/* <h1>Adding record:{addingRecord} - Delete Record:{deletingRecord}</h1> */}
            <button className='btn btn-success' onClick={AddingNewRecord}>Add Record</button> &nbsp;
            <button className='btn btn-primary' onClick={DeleteRecord}>Delete Record</button> &nbsp;
            <button className="btn btn-secondary" onClick = {Alldeletion}>Delete All</button> &nbsp;
            <button className="btn btn-dark text-white">Update</button> &nbsp;
            <button className='btn btn-danger' onClick={Reset}>Reset</button>
            {/* {
                // (addingRecord === "true" && deletingRecord !== "true") ? <div><AddingRecords gettingBudgetTbData={gettingBudgetTbData} newdata={setGettingBudgetTbData}/></div> : 
                // <div>{deletingRecord === "true" ? <div><DeletingRecord gettingBudgetTbData={gettingBudgetTbData} /></div>:<h1></h1>}</div>
                (addingRecord === "true" && deletingRecord !== "true") ? <div><AddingRecords/></div> : 
                <div>{deletingRecord === "true" ? <div><DeletingRecord gettingBudgetTbData={gettingBudgetTbData} /></div>:<h1></h1>}</div>
            } */}
            {/* component */}
            <hr/>
            {/* <h1>Bank Table</h1>
            bank_id, bank_name, saving_m_ava, checking_m_ava
            <hr/>
            <h1>Debt Table</h1>
            debt_id, debt_name, debt_amount, debt_amount_paid, debt_due date
            <hr/>
            <h1>Credit Score</h1>
            query_id, date_checked, previous_score, actual_score, delta = actual - previous_score
            <h3>All credit score</h3>
            id, date_checked, score 
            <hr/>
            <h1>Saving Table</h1>
            date_checked , saving-amount, which_bank
            <hr/>
            <h1>Expenses not budgetable</h1>
            id, expenses_name, amount , store, when */}
        </div>
     );
}
 
export default PersonalBusiness;