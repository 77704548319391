import { initializeApp } from "firebase/app";
import { getFirestore,collection,getDocs} from 'firebase/firestore';
import { getAuth} from 'firebase/auth'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBTTz0T6-HTu52rApUOrLeDMY4iBQlMbzA",
    authDomain: "jrhitech-cf4c9.firebaseapp.com",
    projectId: "jrhitech-cf4c9",
    storageBucket: "jrhitech-cf4c9.appspot.com",
    messagingSenderId: "513220132455",
    appId: "1:513220132455:web:3a75ed8999306c780f9ec2",
    measurementId: "G-CYKWY44HBK"
  };

const app = initializeApp(firebaseConfig); 
const db = getFirestore(app); 
const auth = getAuth(app);
const storages = getStorage(app,"gs://jrhitech-cf4c9.appspot.com"); 

export {app,db,auth,storages};
