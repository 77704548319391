import React from 'react'
import LigeHeader from './LigeHeader'

const AddMemberLige = () => {
  return (
    <div>
        <LigeHeader/>
    <div className='container'>
        <h1>AddMemberLige</h1>
        <p>Please fill out this form to add new member to church</p>
        <div className='container'>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Dob</th>
                    <th scope="col">Age</th>
                    <th scope="col">Numero Carte</th>
                    <th scope="col">Delivered Date</th>
                    <th scope="col">Department</th>
                    <th scope="col">Province</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>Joel Itipo</td>
                        <td>February 14,2024</td>
                        <td>30</td>
                        <td>M2013001</td>
                        <td>September 11,2013</td>
                        <td>Intercession</td>
                        <td>Texas</td>
                    </tr>
                    <tr>
                    <th scope="row">2</th>
                        <td>Joel Itipo</td>
                        <td>February 14,2024</td>
                        <td>30</td>
                        <td>M2013001</td>
                        <td>September 11,2013</td>
                        <td>Intercession</td>
                        <td>Texas</td>
                    </tr>
                    <tr>
                    <th scope="row">3</th>
                    <td>Joel Itipo</td>
                        <td>February 14,2024</td>
                        <td>30</td>
                        <td>M2013001</td>
                        <td>September 11,2013</td>
                        <td>Intercession</td>
                        <td>Texas</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>
  )
}

export default AddMemberLige