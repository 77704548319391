import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom';

const Goal = () => {
    const navigate = useNavigate(); 
    const contactus = () => {
        navigate('/contactus')
    }
    const aboutUs = () => {
        navigate('/aboutus')
    }
    return ( 
        <div className="px-4 pt-3 my-1 text-center border-bottom">
            <h1 className="display-4 fw-bold">Our Goal</h1>
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">
                    "At JR Hi Tech, our goal is to empower businesses to thrive in a rapidly evolving digital landscape. We aim to deliver 
                    innovative solutions, exceptional service, and unparalleled expertise, enabling our clients to stay ahead of the 
                    curve and achieve unprecedented success. Through collaboration and creativity, we strive to revolutionize 
                    the tech industry and transform the way businesses operate, grow, and succeed."
                    {/* {} */}
                    {/* Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s 
                    most popular front-end open source toolkit, featuring Sass variables and mixins, responsive
                     grid system, extensive prebuilt components, and powerful JavaScript plugins. */}
                </p>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                    <button type="button" className="btn btn-light btn-outline-primary btn-lg px-4 me-sm-3" onClick={aboutUs}>Read More</button>
                    <button type="button" className="btn btn-light btn-outline-success btn-lg px-4" onClick={contactus}>Connect to Us</button>
                </div>
            </div>
            <div className="overflow-hidden" >
                <div className="container px-5">
                    {/* <img src="bootstrap-docs.png" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy"/> */}
                </div>
            </div>
        </div>
     );
}
 
export default Goal;