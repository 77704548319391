import React from 'react';
import TypeWriter from 'typewriter-effect'; 
import styled from 'styled-components'; 

const MyTitleMessage = styled.h1`
    @media only screen and (min-width: 1224px) {
        position: absolute;
        width: 100%;
        top: 5rem; 
        z-index: 1;
        margin-top: 3rem;
        // margin-left: 3rem;
        text-align: center;
        strong {
            font-size: 1.5em;
        }

        div {
            color: ${props => props.theme.textColor};
            color: white;
            text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
            font-weight: 100;
            letter-spacing: 7px;
            .main {
                font-size: 40px;
            }
            .sub {
                font-size: 40px;
                letter-spacing: 2px;
                font-weight: bold;
                color: black;
                padding-top: 150px;
            }
        }
    
    }

    @media only screen and (max-width: 320px) {
        position: absolute;
        width: 100%;
        top: 10rem; 
        z-index: 1;
        margin-top: 3rem;
        // margin-left: 3rem;
        text-align: center;
        strong {
            font-size: 1.5em;
        }
        div {
            color: ${props => props.theme.textColor};
            color: white;
            text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
            font-weight: 100;
            letter-spacing: 7px;
            .main {
                font-size: 40px;
            }
            .sub {
                font-size: 30px;
                letter-spacing: 2px;
                font-weight: bold;
                color: black;
                padding-top: 20px;
            }
        }
    }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    position: absolute;
    width: 100%;
    top: 8rem; 
    z-index: 1;
    margin-top: 3rem;
    text-align: center;
    strong {
      font-size: 1.5em;
    }

    div {
        color: ${props => props.theme.textColor};
        color: white;
        text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
        font-weight: 100;
        letter-spacing: 7px;
        .main {
          font-size: 15px;
        }
        .sub {
          padding-top: 40px;
          font-size: 20px;
          letter-spacing: 2px;
          font-weight: bold;
          color: black;
        }
      }
  }
`

const TypeMessage = () => {
    return ( 
        <MyTitleMessage>
            <div className="titleMessage">
                <div className='heading'>
                    <div className='main text-center' >
                        {/* <strong>Hello, There!</strong><br/> */}
                        {/* <span>
                            <strong>JR Hi-Tech</strong>
                        </span> */}
                        {/* <span><strong>we do</strong></span> */}
                    </div>
                    <div className='sub'>
                        <strong>
                            <TypeWriter
                                options={{
                                    strings: ["Web Development", "IT Services", "Network Engineering","Database Adminstrator","Installation","App Design","Web Design","Live Streaming"],
                                    autoStart: true,
                                    loop: true,
                                    pauseFor: 100,
                                    delay: 150
                                }}
                            />
                        </strong>
                    </div>
                </div>
            </div>
            {/* <TypeWriter
                options={{
                    strings:['Hello','World'],
                    autoStart: true,
                    loop:true,
                }}
            /> */}
        </MyTitleMessage>
        
     );
}
 
export default TypeMessage;