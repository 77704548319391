import React, { useState, useEffect } from 'react';
import { useAuthValue } from '../AuthenContext';
import Header from '../common/Header';
import AboutUS from '../pages/Aboutus';


const UserPicked = ({userData}) => {
    const {currentUser} = useAuthValue()
    const [userPicked,setUserPicked] = useState(userData);
    const [isLoading,setIsLoading] = useState(false)
    var filteredUser; 
    if(userPicked){
        filteredUser = userPicked.filter(obj => {
            return obj.userid === currentUser.uid
        })
    }
   
    return ( 
        <div>
            {userPicked && <Header filteredUser={filteredUser}/>}
            {/* {userPicked && <div>Picked is there oooh</div>} */}
            {userPicked === null && <Header filteredUser={null}/>}
            {/* {userPicked === null && <div>NULL</div>} */}
            {/* {userPicked == null && <Header filter={null}/>} */}
            {/* {userPicked ? <Header filter={filteredUser} /> : <Header filter={[]} />} */}
            {/* <Header /> */}
        </div>

     );
}
 
export default UserPicked;