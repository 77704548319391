import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './config'
import { useAuthValue } from './AuthenContext';
import { useNavigate } from 'react-router-dom';
import bgLogin from './Asset/avatar/bglogin.webp'
import logo from './Asset/Logo/logo_transparent.png'
import { onAuthStateChanged} from 'firebase/auth';
// import './Pages/Login.css'
const ContactUs = () => {
    const [contactInformation,setContactInformation] = useState()
    const handleChange = (e) => {
        const {name,value} = e.target
        setContactInformation({...contactInformation,[name]:value})
    }
    const handleSubmit = (e) => {
        e.preventDefault()
    }
    return ( 
        <div>
            <div>
                <div className='container py-5 text-center'>
                    <h2>Contact Us</h2>
                    <h6>Any question or remarks? just write a message</h6>
                </div>
                
                <div>
                    {/* <div> */}
                        <div className="container col-xl-10 col-xxl-8 px-4 py-1">
                        <div className="row align-items-center g-lg-5 py-2">
                            <div className="col-lg-7 text-center text-lg-start">
                                <h4 className="display-4 fw-bold lh-4 mb-5">Contact Information</h4>
                                <p className="col-lg-10 fs-4">Fill up the form and our Team will get back to you within 24 hours.</p>
                            </div>
                            <div className="col-md-10 mx-auto col-lg-5">
                                <form className="p-4 p-md-5 border rounded-3 bg-light" onSubmit={handleSubmit}>
                                    <div className='text-center'><img src={logo} alt='' style={{width:'70px',height:'70px'}}/></div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="floatingInput" placeholder="" name='firstName' onChange={handleChange} required/>
                                        <label htmlFor="floatingInput">Full Name <span style={{color:'red'}}>*</span></label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name='email' onChange={handleChange} required/>
                                        <label htmlFor="floatingInput">Email address <span style={{color:'red'}}>*</span></label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="tel" className="form-control" id="floatingPassword" placeholder="Password" name='password' onChange={handleChange} required/>
                                        <label htmlFor="floatingPassword">Phone <span style={{color:'red'}}>*</span></label>
                                    </div>                                    
                                    <textarea type="tel" className="form-control" id="floatingPassword" cols="30" rows="4" placeholder="Message" name='password' onChange={handleChange} required/>                                   

                                    <div className="checkbox mb-3">
                                        <label>
                                        <input type="checkbox" value="remember-me"/> Remember me
                                        </label>
                                    </div>
                                    <div className='text-center'>
                                        <button className="btn btn-lg btn-primary" type="submit">Send</button>
                                    </div>
                                    <hr className="my-4"/>
                                    <small className="text-muted">By clicking Login, you agree to the terms of use.</small>
                                </form>
                                </div>
                                </div>
                                
                                
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
     );
}
 
export default ContactUs;