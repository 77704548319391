import { addDoc,collection, doc, setDoc } from 'firebase/firestore';
import React, { useState, useEffect } from 'react';
import { useAuthValue } from '../../../AuthenContext';
import { db } from '../../../config';
import { useNavigate, useLocation} from 'react-router-dom';


const AddingRecords = () => {
    const {currentUser} = useAuthValue()
    const navigate = useNavigate()
    const location = useLocation(); 
    const gettingBudgetTbData = location.state
    // console.log('joel getting data -> ',gettingBudgetTbData)
    // const gettingBudgetTbData = props.gettingBudgetTbData
    // const [data,setData] = useState(gettingBudgetTbData)
    const [data,setData] = useState()
    const [groupFormState, setGroupFormState] = useState({
        expenseID:"", 
        expensesName:"",
        amount:"",
        allocation:"",
        userid: ""
    })
        // console.log(gettingBudgetTbData)
    const handleSubmit = (event) => {
        event.preventDefault();
        const dbref = addDoc(collection(db,"budgetTable"),addRecord)
        // console.log(dbref)
        const timeout = setTimeout(() => {
            console.log('this will be called after 2 sec')
        },10000);
        clearTimeout(timeout);
        addingElement()
        // console.log('finished')
        // navigate('/psbms')
    }
    const addingElement = () => {
        // const dbref = addDoc(collection(db,"budgetTable"),addRecord)
        const timeout = setTimeout(() => {
            console.log('this will be called after 2 sec')
        },200000);
        clearTimeout(timeout);
        navigate('/dashboard')
        // window.location.reload(false)    
    }
    const handleChange = (e) => {
        const {name,value} = e.target;
        setGroupFormState({
            ...groupFormState,
            [name]:value,
        });
    }
    
    const addRecord = {
        expenseID:groupFormState.expenseID, 
        expensesName:groupFormState.expensesName,
        amount:groupFormState.amount,
        allocation:groupFormState.allocation,
        userid: currentUser.uid
    }
    // const dbref = addDoc(collection(db,"budgetTable"),addRecord)
    // useEffect(()=>{
    //     console.log('final data->',data)
    // },[data])
    console.log('add data ->',addRecord)
    return ( 
        <div className='container py-2'>
            <table className='table table-bordered border-secondary'>
                <thead>
                    <tr className='text-center'>
                        <th>ID</th>
                        <th>EXPENSES</th>
                        <th>AMOUNT</th>
                        <th>ALOC</th>
                    </tr>
                </thead>
            
                <tbody>
                    {
                        gettingBudgetTbData.map((expense) =>             
                            <tr className='text-center' key={expense.expenseID}>
                                <td>{expense.expenseID}</td>
                                <td>{expense.expensesName}</td>
                                <td><strong>$</strong> {expense.amount}</td>
                                <td><strong>$</strong> {expense.allocation}</td>
                            </tr>
                        )
                    }     
                </tbody>
            </table>
            <form onSubmit={handleSubmit}>
                <h4>Adding New Record</h4>
                <hr/>
                <div className='row g-3 align-items-center'>
                
                    <div className='col-auto'>
                        <input type="text" id="inputexpenseid" name="expenseID" className='form-control' aria-describedby='textHelpInline' placeholder='Expense Id' onChange={handleChange}/>
                    </div>     

                    <div className='col-auto'>
                        <input type="text" id="inputexpenseid" name="expensesName" className='form-control' aria-describedby='textHelpInline' placeholder='Expenses Name' onChange={handleChange}/>
                    </div>    

                    <div className='col-auto'>
                        <input type="text" id="inputexpenseid" name="amount" className='form-control' aria-describedby='textHelpInline' placeholder='Amount' onChange={handleChange}/>
                    </div>    

                    <div className='col-auto'>
                        <input type="text" id="inputexpenseid" name="allocation" className='form-control' aria-describedby='textHelpInline' placeholder='Allocation' onChange={handleChange}/>
                    </div>   

                    {/* <div className='col-auto'>
                        <input type='text' id='inputuser' name='userid' value={currentUser.uid} className='form-control' aria-describedby='textHelpInline' placeholder='User id' onChange={handleChange} disabled/>
                    </div>                                          */}
                </div><br/>
                <button className='btn btn-success' >Submit New Record</button>
            </form>
        </div>
     );
}
 
export default AddingRecords;