import React from 'react'

const Field = () => {
    return ( 
        <div className="container px-2 py-2" id="icon-grid">
        <h2 className="pb-1 border-bottom text-info shadow">Sector of Expertise</h2>
    
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
          <div className="col d-flex align-items-start">
            {/* <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#bootstrap"/></svg> */}
            <div>
              <h4 className="fw-bold mb-0">Information Technologies</h4>
              <p>
                Helping individuals, organizations, and businesses manage, maintain, and optimize their IT systems, infrastructure, and processes. 
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            {/* <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#cpu-fill"/></svg> */}
            <div>
              <h4 className="fw-bold mb-0">Website Design</h4>
              <p>
                Website design refers to the process of creating the visual and functional aspects of a website. It encompasses various elements, including: 
                UX, UI, Color scheme and typography, Content creation. 
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            {/* <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#calendar3"/></svg> */}
            <div>
              <h4 className="fw-bold mb-0">Web Development</h4>
              <p>
                Refers to the process of creating and building websites, web applications, and online platforms using various technologies 
                and programming languages. It involves a wide range of activities. 
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            {/* <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#home"/></svg> */}
            <div>
              <h4 className="fw-bold mb-0">Software Installation</h4>
              <p>
              refers to the process of setting up and configuring a software application, framework, or platform on a server or hosting environment.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            {/* <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#speedometer2"/></svg> */}
            <div>
              <h4 className="fw-bold mb-0">NetWork Engineering</h4>
              <p>
                Daeals with the design, implementation, and management of computer networks, local area networks (LANs), 
              wide area networks (WANs), and the Internet.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            {/* <svg class="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#toggles2"/></svg> */}
            <div>
              <h4 className="fw-bold mb-0">App Design</h4>
              <p>
                The process of creating and envisioning the layout, (UI), and (UX) of a mobile or web application. It 
                involves designing the visual and interactive elements of an app.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            {/* <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#geo-fill"/></svg> */}
            <div>
              <h4 className="fw-bold mb-0">Database Adminstration</h4>
              <p>
                The process of managing, maintaining, and ensuring the smooth operation of databases, which are collections of data organized 
                in a specific way to facilitate easy access, retrieval, and manipulation.</p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            {/* <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#tools"/></svg> */}
            <div>
              <h4 className="fw-bold mb-0">Live Streaming</h4>
              <p>
                The real-time broadcast of video and audio content over the internet. It allows individuals or organizations to share events, activities,
                or experiences with a live audience, in real-time, regardless of geographical location. 
              </p>
            </div>
          </div>
        </div>
      </div>
     );
}
 
export default Field;