import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';

const BootCamp = () => {
    const history = useNavigate()
    // const [option,setOption] = useState("")
    const reg_python_auto = () =>{
        history('register',{state:'automation'})
    }

    const reg_oop = () =>{
        history('register',{state:'Object Oriented Programming'})
    }

    const web_dev = () => {
        history('register',{state:'Web Development'})
    }
    return (
        <div className='container'>
            <div>
                <div className="container py-4">
                    <header className="pb-3 mb-4 border-bottom">
                        <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" className="me-2" viewBox="0 0 118 94" role="img"><title>Bootstrap</title><path fillRule="evenodd" clipRule="evenodd" d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z" fill="currentColor"></path></svg>
                            <span className="fs-4">JR Hi-Tech BootCamp</span>
                        </a>
                    </header>

                    <div className="p-4 mb-3 bg-info text-dark rounded-3">
                        <div className="container-fluid py-2">
                            <h1 className="display-3 fw-bold text-light">BootCamp Workshop</h1>
                            <p className="col-md-12 fs-5">
                            "JR HiTech Bootcamp is a cutting-edge program designed for students aged 10-22 to explore the world of technology and innovation. 
                            Our expert instructors will guide students through hands-on projects, interactive exercises, and collaborative challenges, 
                            fostering a supportive community where young minds can grow and thrive. From coding and robotics to AI and beyond, 
                            JR HiTech Bootcamp empowers the next generation of tech leaders to shape the future."
                            </p>
                            <button className="btn btn-dark btn-lg" type="button">Read More</button>
                        </div>
                    </div>

                    <div className="row align-items-md-stretch">
                        <div className="col-md-4">
                            <div className="h-80 p-3 text-white bg-dark rounded-3">
                                <h2>Youth Piano Center</h2>
                                <p>
                                    "Welcoming hub where children aged 5-18 can discover the creativity of music. Our expert instructors 
                                    guide students in developing piano skills, exploring creativity, and building self-confidence. Through group classes, recitals, 
                                    and performances, we foster a supportive community where young minds can flourish and grow." 
                                </p>
                                <button className="btn btn-primary btn-outline-light" type="button" onClick={reg_python_auto}>Register Now</button>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="h-80 p-3 bg-dark text-light border rounded-3">
                                <h2>Cisco Packet Tracer</h2>
                                <p>
                                    "Dynamic hub where students aged 16+ can discover the creativity of network engineering. 
                                    Our expert instructors guide students in developing hands-on skills, exploring innovative solutions, and building 
                                    self-confidence. Through interactive labs, simulations,and collaborative projects, we foster a supportive community 
                                    where future network engineers can thrive and grow."
                                </p>
                                <button className="btn btn-outline-light btn-primary" type="button" onClick={reg_oop}>Register Now</button>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="h-80 p-3 bg-dark text-light border rounded-3">
                                <h2>Flyer Design</h2>
                                <p>
                                    "A creative hub where students aged 13+ can discover the power of design. Our expert 
                                    instructors guide students in developing design skills, exploring creativity, and building self-confidence. 
                                    Through hands-on projects, interactive exercises, and collaborative critiques, we foster a supportive 
                                    community where young designers can flourish and grow."    
                                </p>
                                <button className="btn btn-outline-light btn-primary" type="button" onClick={web_dev}>Register Now</button>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row align-items-md-stretch">
                        <div className="col-md-4">
                            <div className="h-80 p-3 text-white bg-dark rounded-3">
                                <h2>Python Automation</h2>
                                <p>
                                    "A dynamic hub where students aged 16+ can discover the power of automation. 
                                    Our expert instructors guide students in developing Python skills, exploring automation techniques, 
                                    and building self-confidence. Through hands-on projects, interactive exercises, and collaborative 
                                    problem-solving, we foster a supportive community where future automation experts can thrive and grow."
                                </p>
                                <button className="btn btn-primary btn-outline-light" type="button" onClick={reg_python_auto}>Register Now</button>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="h-70 p-3 bg-dark text-light border rounded-3">
                                <h2>Object Oriented P.</h2>
                                <p>
                                    "A transformative hub where students aged 16+ can master 
                                    the art of code. Our expert instructors guide students in developing object-oriented programming
                                    skills, exploring creative solutions, and building self-confidence. Through immersive projects,
                                    interactive exercises, and collaborative critiques, we foster a supportive community where 
                                    future coding masters can flourish and grow."
                                </p>
                                <button className="btn btn-outline-light btn-primary" type="button" onClick={reg_oop}>Register Now</button>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="h-70 p-3 bg-dark text-light border rounded-3">
                                <h2>Web Developpment</h2>
                                <p>
                                "A dynamic hub where students aged 16+ can master the art of web development.
                                 Our expert instructors guide students in developing hands-on skills, exploring creative solutions, 
                                 and building self-confidence. Through immersive projects, interactive exercises, and collaborative
                                 critiques, we foster a supportive community where future web developers can thrive and grow."
                                </p>
                                <button className="btn btn-outline-light btn-primary" type="button" onClick={web_dev}>Register Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default BootCamp;