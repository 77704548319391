import React, { useState, useEffect } from 'react';
import { useNavigate, Link} from 'react-router-dom';
import { auth, db } from '../config';
import { createUserWithEmailAndPassword,sendEmailVerification } from 'firebase/auth';
// import DashBoard from './../Dashboard';
import { addDoc, collection } from 'firebase/firestore';
import { useAuthValue } from '../AuthenContext';
import { getStorage, ref, uploadBytes } from 'firebase/storage'; 

const Register = () => {
    const Navigate = useNavigate()
    const {currentUser} = useAuthValue()
    const [picturesRef,setPicturesRef] = useState()
    const [refImage,setRefImage] = useState()
    const [DataForm,setDataForm] = useState({
        firstName:"", 
        lastName:"",
        username: "", 
        email: "",
        password:"",
        copassword:"",
        avatar: {},
        address:"",
        address2:"", 
        country:"",
        usstate:"", 
        zip:""
    })
    const handleChange = (e) => {
        const {name,value}= e.target
        setDataForm({ ...DataForm,[name]:value})
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log('password : ',DataForm.password,'confirm - password:',DataForm.copassword)
        if(DataForm.password == DataForm.copassword){
            console.log("Password Matches")
            createUserWithEmailAndPassword(auth,DataForm.email,DataForm.password)
            .then(() => {
                // console.log(res.user)
                sendEmailVerification(auth.currentUser)
                .then(() => {
                    Navigate('/dashboard')
                }).catch((err) => alert(err.message))
            })
            const storage = getStorage();
            const imagesUploading = ref(storage, `images/${picturesRef.name}`)
            console.log('JOEL image uploading:',imagesUploading)
            uploadBytes(imagesUploading, picturesRef).then((snapshot) => {
                console.log('uploaded a blodb or file!')
            })
            setRefImage(imagesUploading)
        } 
        else{
            console.log('No Matching Password')
        }
    }
    useEffect(() => {
        if(currentUser !== null){
            console.log('ref fullpath: ',refImage)
            const profileUser = addDoc(collection(db,'userProfile'),{
                Country : DataForm.country, 
                FirstName: DataForm.firstName, 
                LastName: DataForm.lastName,
                address : DataForm.address +' '+ DataForm.address2,
                avatar: refImage.fullPath, 
                userid : currentUser.uid,
                username: DataForm.username, 
                usstate : DataForm.usstate,
                zip : DataForm.zip, 
            })
            setDataForm({
                firstName:"", 
                lastName:"",
                username: "", 
                email: "",
                password:"",
                copassword:"",
                avatar: "",
                address:"",
                address2:"", 
                country:"",
                usstate:"", 
                zip:""
            });
        }
        // console.log('something is changing')
    },[currentUser])
    const handleImage = (e) => {
        setPicturesRef(e.target.files[0])
    }
    console.log('picture: ',picturesRef,' ref picture: ',refImage, 'avatar: ',DataForm.avatar)
    return (  
        <div>
            <div className='container'>
                <div className='row g-5'>
                    <div className='col-md-7 col-lg-8'>
                    <h4 className='mb-3 py-2'>Register to JR Hi-Tech.</h4>
                        <form className='needs-validation' noValidate onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <label htmlFor='FirstName' className='form-label'>First Name</label> 
                                    <input type="text" className="form-control" id="firstName" name="firstName" placeholder='Enter first name' required onChange={handleChange}/>
                                    <div className="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <label htmlFor='LastName' className='form-label'>Last Name</label> 
                                    <input type="text" className="form-control" id="lastName" name="lastName" placeholder='Enter Last name' required onChange={handleChange}/>
                                    <div className="invalid-feedback">
                                        Valid Last name is required.
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <label htmlFor='email' className='form-label'>E-mail</label> 
                                    <input type="email" className="form-control" id="email" name="email" placeholder='Enter E-mail' required onChange={handleChange}/>
                                    <div className="invalid-feedback">
                                        Valid e-mail is required.
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <label htmlFor='username' className='form-label'>Username</label> 
                                    <input type="text" className="form-control" id="username" name="username" placeholder='Enter Username' required onChange={handleChange}/>
                                    <div className="invalid-feedback">
                                        Valid username is required.
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <label htmlFor="pwd" className='form-label'>Password</label> 
                                    <input type="password" className="form-control" id="password" name="password" placeholder='Enter Password' required onChange={handleChange}/>
                                    <div className="invalid-feedback">
                                        Valid Password is required.
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <label htmlFor="co_pwd" className='form-label'>Confirm-Password</label> 
                                    <input type="Password" className="form-control" id="copassword" name="copassword" placeholder='Enter Confirm Password' required onChange={handleChange}/>
                                    <div className="invalid-feedback">
                                        Valid Password is required.
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <label htmlFor='avatar' className='form-label'>Avatar</label>
                                    <input type='file' name="avatar" className='form-control' id="avatar" placeholder='Enter Avatar' onChange={handleImage}/>
                                </div>
                                <div className='col-12'>
                                    <label htmlFor='address' className='form-label'>Address</label>
                                    <input type='text' className='form-control' id="address" name="address" placeholder='Enter your address' onChange={handleChange}/>
                                </div>
                                
                                <div className="col-12">
                                    <label htmlFor='address2' className='form-label'>Address 2 (Optional)</label>
                                    <input type='text' className='form-control' id="address2" name='address2' placeholder='Apartment or suite' onChange={handleChange}/> 
                                </div>

                                <div className="col-md-5">
                                    <label htmlFor="country" className="form-label">Country</label>
                                    <select className="form-select" name="country" id='country' onChange={handleChange} required>
                                        <option value="">Choose ...</option>
                                        <option value="United States">United States</option>
                                    </select>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="state" className="form-label">State</label>
                                    <select name="usstate" id="state" className="form-select" onChange={handleChange}>
                                        <option value="">Choose...</option>
                                        <option value="Texas">Texas</option>
                                        <option value="California">California</option>
                                    </select>
                                </div>

                                <div className='col-md-3'>
                                    <label htmlFor="zip" className="form-label">Zip</label>
                                    <input type='text' name="zip" className='form-control' id='zip' placeholder='zip code' onChange={handleChange}/>
                                </div>
                               
                                {/* <label>Confirm Password</label>
                                <input type="password" htlmfor="co-pwd" id="co-password" name="co-password" placeholder='confirm your password'/> */}
                                {/* {if pwd === co_pwd ? show gree password matches else show red double check password} */}
                            </div>
                            <div>
                                <br/>
                                <button className='btn btn-primary'>Register</button>
                            </div>
                        </form>
                        <br/>
                        <br/>
                    </div>                
                    
                </div>

            </div>
            

        </div>
    );
}
 
export default Register;
<div>
    <h1>Register to JR system.</h1>
</div>