import React,{useState, useEffect, useCallback} from 'react'
import Hot from '../images/v2/01d.png'
import Rain from '../images/v2/09d.png'
import Clouds from '../Asset/weatherIcon/snow-cloud-128.webp'
import Clear from '../images/v2/02n.png'
import axios from 'axios'; 
import GetGeolocation from './GetGeolocation';

const WeatherApi = () => {
    const [weather,setWeather] = useState([]);
    // const [isLoading,setLoading] = useState(true);
    const [latitude,setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [isAvailable,setIsAvailable] = useState(false); 
    const [reverseInfo, setReverseInfo] = useState([]); 
    // const [degree,setDegree] = useState('metric');
    // const apikey = '48e22404c62d52a16835439a85c6812b'

    const cleanUpWeatherData = useCallback((rawData) => {
        console.log('clean function:',rawData)
        const  d = new Date();
        const dayNumber = d.getDay() 
        // const Location = rawData.alerts[0].sender_name
        const timezone = rawData.timezone; 
        const dt = rawData.current.dt; 
        const weather_descrip = rawData.current.weather[0].main;
        const temperature = rawData.current.temp;
        const feels_like = rawData.current.feels_like;
        const humidity = rawData.current.humidity;
        const wind_speed = rawData.current.wind_speed;
        const weaterIcon = rawData.current.weather[0].icon;
        const daily_morn = rawData.daily[dayNumber].feels_like.morn; 
        const daily_day = rawData.daily[dayNumber].feels_like.day;
        const daily_evening = rawData.daily[dayNumber].feels_like.eve;
        const day1 = rawData.daily[1].temp.day;
        const day1Max = rawData.daily[1].temp.max; 
        const day1Min = rawData.daily[1].temp.min;
        const day1Hum = rawData.daily[1].humidity;
        const day1Wind = rawData.daily[1].wind_speed; 
        const day1Feels = rawData.daily[1].feels_like.day;
        const day1Descr = rawData.daily[1].weather[0].main;
        const day2 = rawData.daily[2].temp.day;
        const day2Max = rawData.daily[2].temp.max; 
        const day2Min = rawData.daily[2].temp.min;
        const day2Hum = rawData.daily[2].humidity;
        const day2Wind = rawData.daily[2].wind_speed; 
        const day2Feels = rawData.daily[2].feels_like.day;
        const day2Descr = rawData.daily[2].weather[0].main;
        const day3 = rawData.daily[3].temp.day;
        const day3Max = rawData.daily[3].temp.max; 
        const day3Min = rawData.daily[3].temp.min;
        const day3Hum = rawData.daily[3].humidity;
        const day3Wind = rawData.daily[3].wind_speed; 
        const day3Feels = rawData.daily[3].feels_like.day;
        const day3Descr = rawData.daily[3].weather[0].main;
        const day4 = rawData.daily[4].temp.day;
        const day4Max = rawData.daily[4].temp.max; 
        const day4Min = rawData.daily[4].temp.min;
        const day4Hum = rawData.daily[4].humidity;
        const day4Wind = rawData.daily[4].wind_speed; 
        const day4Feels = rawData.daily[4].feels_like.day;
        const day4Descr = rawData.daily[4].weather[0].main;
        const day5 = rawData.daily[5].temp.day;
        const day5Max = rawData.daily[5].temp.max; 
        const day5Min = rawData.daily[5].temp.min;
        const day5Hum = rawData.daily[5].humidity;
        const day5Wind = rawData.daily[5].wind_speed; 
        const day5Feels = rawData.daily[5].feels_like.day;
        const day5Descr = rawData.daily[5].weather[0].main;
        const day6 = rawData.daily[6].temp.day;
        const day6Max = rawData.daily[6].temp.max; 
        const day6Min = rawData.daily[6].temp.min;
        const day6Hum = rawData.daily[6].humidity;
        const day6Wind = rawData.daily[6].wind_speed; 
        const day6Feels = rawData.daily[6].feels_like.day;
        const day6Descr = rawData.daily[6].weather[0].main;
        const updatedWeatherData = {timezone,dt,weather_descrip,temperature,feels_like,humidity,wind_speed,weaterIcon,daily_day,daily_morn,daily_evening,day1,
        day1Max,day1Min,day2,day2Max,day2Min,day3,day3Max,day3Min,day4,day4Max,day4Min,day5,day5Max,day5Min,day6,day6Max,day6Min,
        day6Hum,day6Wind,day6Feels,day6Descr,day5Hum,day5Wind,day5Feels,day5Descr,day4Hum,day4Wind,day4Feels,day4Descr,day3Hum,day3Wind,day3Feels,day3Descr
    ,day3Hum,day3Wind,day3Feels,day3Descr,day2Hum,day2Wind,day2Feels,day2Descr,day1Hum,day1Wind,day1Feels,day1Descr}
        setWeather(updatedWeatherData)
    },[])

    const ReverseGeoCleanUp = useCallback((rawData) => {
        const countryCode= rawData.countryCode
        const locality = rawData.locality
        const city = rawData.city
        const zipCode = rawData.postcode
        const provState = rawData.principalSubdivision 
        const finalInfo = {countryCode,locality,city,zipCode,provState}
        setReverseInfo(finalInfo)
    },[])

    const urlReverse = "https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en"
    const reverseGeoCoding = () => {
        axios.get(`${urlReverse}`)
        .then((response) => {
            console.log('here ooh',response.data)    
            ReverseGeoCleanUp(response.data); 
        })
        .catch((error) => {
            console.log(error);
        })
    }
    const url =`https://api.openweathermap.org/data/3.0/onecall?lat=${latitude}&units=imperial&lon=${longitude}&appid=48e22404c62d52a16835439a85c6812b`
    // const url = 'https://api.openweathermap.org/data/3.0/onecall?lat=32.8467&units=imperial&lon=-96.9719&exclude=alerts,minutely&appid=48e22404c62d52a16835439a85c6812b'
    const getData = () => {
        if(isAvailable === true){
            axios.get(`${url}`)
            .then((response) => {
                cleanUpWeatherData(response.data)
            })
        }
        else{
            console.log('let wait')
        }
        
    }
    const getLocation = () => {
        console.log('clicked-ok')
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(showPosition);
        }
        else{
            console.log('not supported by this browser')
        }
    }
    const showPosition = (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        timeout();
        clearTimeout(timeout);
    }

    const timeout = () => {
        console.log("ok baby")
        setTimeout(() => {
        console.log('this will be called after 2 sec')
        setIsAvailable(true)
        },300)
    }

    useEffect(() => {
        getLocation();
    })
    useEffect(() => {
        reverseGeoCoding();
        getData();
        // setIsAvailable(true)
        console.log('is Available->',isAvailable)
    },[isAvailable]); 
    // console.log('weather',weather)
    // function currentTime(timezone,dt){
    //     let dateTime = new Date(dt * 1000 + (timezone*1000));
    //     let weekday = dateTime.toLocaleDateString('en-us',{weekday:'long'});
    //     return `${weekday}`
    //     // return 
    // }
    function addDays(days){
        var result = new Date(); 
        result.setDate(result.getDate()+days); 
        return result.toLocaleDateString('en-us',{weekday:'long'}); 
    }
    console.log("old date",new Date().toLocaleDateString('en-us',{weekday:'long'}),' new date ',addDays(1));
    const weekday = new Date().toLocaleDateString('en-us',{weekday:'long'});
    // console.log('today ',weekday,'nextDay -> ',weekday+1)
    // console.log(currentTime('weekday ->',weather.timezone,weather.dt));
    
    console.log('global is Available->',isAvailable)

    return ( 
    <div className='container'>
        <h1 className='text-center py-2'>Weather App</h1>
        <h2>{reverseInfo.locality}</h2>
        <h5>{reverseInfo.provState}-{reverseInfo.zipCode}</h5>
        <h6> Today:{new Date().toDateString("en-us")} </h6>
        <div className="card mb-1 py-1 px-1  bg-dark text-white" >
            <div className="row g-0">
                { isAvailable ? 
                    weather.temperature < 78 ? 
                    <div className="col-md-14">
                        <div className="card-group py-2 ">
                            <div className="card mx-1" style={{width: "19rem"}}>
                                <img src={Clouds} className="img-fluid rounded-start" alt="..."/>
                                <div className="card-body bg-warning text-dark">
                                    <h4 className="card-title text-center text-danger">{weekday}</h4>
                                    <hr/>
                                    <div className='text-center'>
                                        <h3 className=''>&nbsp;&nbsp;{weather.temperature} ° F</h3>
                                        <h5>&nbsp;&nbsp;&nbsp;{weather.weather_descrip}</h5>
                                    </div>
                                    <p className="card-text">
                                        <ul>
                                            <li><strong>Humidity</strong> &nbsp; <span className='text-light'>{weather.humidity}</span></li>
                                            <li>
                                                <strong> Feels likes</strong>&nbsp; 
                                                <span className='text-light'>{weather.feels_like} ° F</span> 
                                            </li>
                                            <li><strong>Wind Speed</strong> &nbsp; <span className='text-light'>{weather.wind_speed}</span></li>                                    
                                        </ul>
                                    </p>
                                    {/* <a href="#" className="btn btn-primary">See Details</a> */}
                                </div>
                                
                            </div>   
                            {/* ----                      */}
                            <div className="card mx-1" style={{width: "19rem"}}>
                                <img src={Rain} className="img-fluid rounded-start" alt="..."/>
                                <div className="card-body bg-info text-dark">
                                    <h4 className="card-title text-center text-danger">{addDays(1)}</h4>
                                    <hr/>
                                    <div className='text-center'>
                                        <h3 className=''>&nbsp;&nbsp;{weather.day1} ° F</h3>
                                        <h5>&nbsp;&nbsp;&nbsp;{weather.day1Descr}</h5>
                                        <p>Max <strong>{weather.day1Max}°F</strong> - Min <strong>{weather.day1Min}°F</strong></p>
                                    </div>
                                    <p className="card-text">
                                        <ul>
                                            <li>
                                                <strong>Humidity</strong> &nbsp;
                                                <span className='text-light'>{weather.day1Hum}</span></li>
                                            <li>
                                                <strong> Feels likes</strong>&nbsp; 
                                                <span className='text-light'>{weather.day1Feels} ° F</span> 
                                            </li>
                                            <li>
                                                <strong>Wind Speed</strong> &nbsp; 
                                                <span className='text-light'>{weather.day1Wind}</span>
                                            </li>
                                        </ul>
                                    </p>
                                    {/* <a href="#" className="btn btn-primary">See Details</a> */}
                                </div>
                            </div>            
                            {/* ----                      */}
                            <div className="card mx-1" style={{width: "19rem"}}>
                                <img src={Clouds} className="img-fluid rounded-start" alt="..."/>
                                <div className="card-body bg-info text-dark">
                                    <h4 className="card-title text-center text-danger">{addDays(2)}</h4>
                                    <hr/>
                                    <div className='text-center'>
                                        <h3 className=''>&nbsp;&nbsp;{weather.day2} ° F</h3>
                                        <h5>&nbsp;&nbsp;&nbsp;{weather.day2Descr}</h5>
                                        <p>Max <strong>{weather.day2Max}°F</strong> - Min <strong>{weather.day2Min}°F</strong></p>
                                    </div>
                                    <p className="card-text">
                                        <ul>
                                            <li>
                                                <strong>Humidity</strong> &nbsp;
                                                <span className='text-light'>{weather.day2Hum}</span></li>
                                            <li>
                                                <strong> Feels likes</strong>&nbsp; 
                                                <span className='text-light'>{weather.day2Feels} ° F</span> 
                                            </li>
                                            <li>
                                                <strong>Wind Speed</strong> &nbsp; 
                                                <span className='text-light'>{weather.day2Wind}</span>
                                            </li>
                                        </ul>
                                    </p>
                                    {/* <a href="#" className="btn btn-primary">See Details</a> */}
                                </div>
                            </div>        
                            {/* ----                      */}
                            <div className="card mx-1" style={{width: "19rem"}}>
                                <img src={Clear} className="img-fluid rounded-start" alt="..."/>
                                <div className="card-body bg-info text-dark">
                                    <h4 className="card-title text-center text-danger">{addDays(3)}</h4>
                                    <hr/>
                                    <div className='text-center'>
                                        <h3 className=''>&nbsp;&nbsp;{weather.day3} ° F</h3>
                                        <h5>&nbsp;&nbsp;&nbsp;{weather.day3Descr}</h5>
                                        <p>Max <strong>{weather.day3Max}°F</strong> - Min <strong>{weather.day3Min}°F</strong></p>
                                    </div>
                                    <p className="card-text">
                                        <ul>
                                            <li>
                                                <strong>Humidity</strong> &nbsp;
                                                <span className='text-light'>{weather.day3Hum}</span></li>
                                            <li>
                                                <strong> Feels likes</strong>&nbsp; 
                                                <span className='text-light'>{weather.day3Feels} ° F</span> 
                                            </li>
                                            <li>
                                                <strong>Wind Speed</strong> &nbsp; 
                                                <span className='text-light'>{weather.day3Wind}</span>
                                            </li>
                                        </ul>
                                    </p>
                                    {/* <a href="#" className="btn btn-primary">See Details</a> */}
                                </div>
                            </div>       
                             {/* ----                      */}
                             <div className="card mx-1" style={{width: "19rem"}}>
                                <img src={Clouds} className="img-fluid rounded-start" alt="..."/>
                                <div className="card-body bg-info text-dark">
                                    <h4 className="card-title text-center text-danger">{addDays(4)}</h4>
                                    <hr/>
                                    <div className='text-center'>
                                        <h3 className=''>&nbsp;&nbsp;{weather.day4} ° F</h3>
                                        <h5>&nbsp;&nbsp;&nbsp;{weather.day4Descr}</h5>
                                        <p>Max <strong>{weather.day4Max}°F</strong> - Min <strong>{weather.day4Min}°F</strong></p>
                                    </div>
                                    <p className="card-text">
                                        <ul>
                                            <li>
                                                <strong>Humidity</strong> &nbsp;
                                                <span className='text-light'>{weather.day4Hum}</span></li>
                                            <li>
                                                <strong> Feels likes</strong>&nbsp; 
                                                <span className='text-light'>{weather.day4Feels} ° F</span> 
                                            </li>
                                            <li>
                                                <strong>Wind Speed</strong> &nbsp; 
                                                <span className='text-light'>{weather.day4Wind}</span>
                                            </li>
                                        </ul>
                                    </p>
                                    {/* <a href="#" className="btn btn-primary">See Details</a> */}
                                </div>
                            </div>         
                        </div>
                    </div> : 
                    <div className="col-md-4 ">
                        <img src={Clouds} className="img-fluid rounded-start" alt="..."/>
                        <div>
                            <h1 className=''>&nbsp;&nbsp;{weather.temperature} ° F</h1>
                            <h5>&nbsp;&nbsp;&nbsp;{weather.weather_descrip}</h5>
                        </div>
                    </div>
                    
                    : 
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>                
                }
                {/* <div className="col-md-8 ">
                    <div className="card-body">
                        <h5 className="card-title">{weather.Location}</h5>
                        <h4 className="card-text mx-5">
                            <strong>Humidity</strong> &nbsp; <span className='text-info'>{weather.humidity}</span> <strong>&nbsp; Feels likes</strong>&nbsp; 
                            <span className='text-info'>{weather.feels_like} ° F</span> 
                            <strong>&nbsp; Wind Speed</strong> &nbsp; <span className='text-info'>{weather.wind_speed}</span>
                        </h4>
                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                </div> */}
            </div>
               
        </div>
            <div className="card-group py-1">
                    <div className="card " height='100' width='150px'>
                        <h1>Morning</h1>
                        <img src={Clouds} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h1 className="card-title">{weather.daily_morn} ° F</h1>
                            <p className="card-text">Info</p>
                            {/* <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
                        </div>
                    </div>
                    <div className="card">
                        <h1>Day</h1>
                        <img src={Hot} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h1 className="card-title">{weather.daily_day} ° F</h1>
                            <p className="card-text">Info</p>
                           {/* <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
                        </div>
                    </div>
                    <div className="card">
                        <h1>Evening</h1>
                        <img src={Clear} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h1 className="card-title">{weather.daily_evening} ° F</h1>
                            <p className="card-text">Info</p>
                            {/* <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
                        </div>
                    </div>
                </div>
    </div> 
    );
}
 
export default WeatherApi;