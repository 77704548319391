import React from 'react'
import { useNavigate } from 'react-router-dom';

const Services = () => {
    const history = useNavigate()
    // const [option,setOption] = useState("")
    const bookservice = () => {
        console.log("Just clicked on this Joe");
        history(`register`,{state:'automation'})
    }
    return ( 
        <div className="container">
            {/* <h1>Our Services and Pricing</h1> */}
            <header>
                <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                    {/* <h1 className="display-4 fw-normal">Pricing</h1> */}
                    <p className="fs-5 text-muted">
                        Welcome to JR HiTech's Service Pages! Here, you'll discover a comprehensive suite of solutions designed 
                        to propel your business forward. From cutting-edge web development to innovative digital marketing strategies, 
                        our expert team is dedicated to helping you succeed. Explore our service offerings to learn how we can help you:
                        Whether you're looking to revamp your brand, boost your online visibility, or streamline your operations, we've 
                        got you covered. Dive in and explore how JR HiTech can help your business thrive in the digital age!
                    </p>
                    <hr/>
                    <h3 className="display-8 fw-normal">Service Pricing</h3>
                    <p className="fs-5 text-muted">"Our Service Pricing: Tailored to Your Needs
                        <br/>At JR HiTech, we understand that every project is unique, which is why we base our pricing on the specific
                        tasks required to deliver exceptional results. This way, you only pay for what you need, ensuring a cost-effective
                        solution that fits your budget. Explore our service pricing structure and discover the value we can bring to your project."
                    </p>
                </div>
            </header>
            <main>
            {/* <button type="button" className=" btn btn-lg btn-dark" onclick={bookservice}>Book Service</button> */}
                <div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-dark">
                            <div className="card-header bg-dark py-3 border-dark">
                                <h4 className="my-0 fw-normal text-danger">Consultation</h4>
                            </div>
                            <div className="card-body ">
                                <h1 className="card-title pricing-card-title">$80<small className="text-muted fw-light">/hr</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Envisioning future framework</li>
                                    <li>Internet of Things (IoT)</li>
                                    <li>Technology Integration</li>
                                    <li>Business Process Optimization</li>
                                    <li>Change Management Consulting</li>
                                </ul><hr/>
                                <button type="button" className=" btn btn-lg btn-dark" onclick={bookservice}>Book Service</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-dark">
                            <div className="card-header bg-dark py-3 border-dark">
                                <h4 className="my-0 fw-normal text-danger">IT Services</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$150<small className="text-muted fw-light">/hr</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Managed IT Services</li>
                                    <li>Software Development Services</li>
                                    <li>IT Consulting Services</li>
                                    <li>Infrastructure Services</li>
                                    <li>Data Services</li>
                                </ul><hr/>
                                <button type="button" className=" btn btn-lg btn-dark">Book Service</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-dark">
                            <div className="card-header py-3 bg-dark border-dark">
                                <h4 className="my-0 fw-normal text-danger">Installation</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$80<small className="text-muted fw-light">/service</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Network Installation</li>
                                    <li>Server Installation</li>
                                    <li>Software Installation </li>
                                    <li>Cabling Installation</li>
                                    <li>Workstation Installation</li>
                                </ul><hr/>
                                <button type="button" className=" btn btn-lg btn-dark" value={"Installation"}>Book Service</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-dark">
                            <div className="card-header py-3 text-white bg-dark border-dark">
                                <h4 className="my-0 fw-normal text-danger">Network Technician</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$200<small className="text-muted fw-light">/dy</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                <li>Network Troubleshooting</li>
                                <li>Network Maintenance</li>
                                <li>Network Security</li>
                                <li>Network Configuration</li>
                                <li>Network Upgrades</li>
                                </ul><hr/>
                                <button type="button" className=" btn btn-lg btn-dark">Book Service</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    <h3 className="display-8 fw-normal">Contract Pricing</h3>
                    <p className="fs-5 text-muted">
                    "Customized Contract Pricing: Tailored to Your Business Needs
                    <br/>
                    At JR HiTech, we recognize that every organization has unique requirements. That's why we offer bespoke contract pricing,
                    carefully crafted to align with your specific business needs. Our flexible approach ensures a mutually beneficial partnership,
                    tailored to your goals and budget. Discover how our contract pricing can help drive your success."
                    </p>
                </div>
                
                <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                   
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">IT Services</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$550<small className="text-muted fw-light">/yr</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Network Troubleshooting</li>
                                    <li>Network Maintenance</li>
                                    <li>Network Security</li>
                                    <li>Network Configuration</li>
                                    <li>Network Upgrades</li>
                                </ul>
                                <button type="button" className=" btn btn-lg btn-primary">Get started with $50</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Web & Apps Devs</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$850<small className="text-muted fw-light">/yr</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                <li>Network Troubleshooting</li>
                                    <li>Network Maintenance</li>
                                    <li>Network Security</li>
                                    <li>Network Configuration</li>
                                    <li>Network Upgrades</li>
                                </ul>
                                <button type="button" className=" btn btn-lg btn-primary">Get started</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-primary">
                            <div className="card-header py-3 text-white bg-primary border-primary">
                                <h4 className="my-0 fw-normal">Enterprise</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$2000<small className="text-muted fw-light">/yr</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Network Troubleshooting</li>
                                    <li>Network Maintenance</li>
                                    <li>Network Security</li>
                                    <li>Network Configuration</li>
                                    <li>Network Upgrades</li>
                                </ul>
                                <button type="button" className=" btn btn-lg btn-primary">Contact us</button>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
     );
}
 
export default Services;