import React, { Component } from 'react'
import { useAuthValue } from './AuthenContext';
import { signOut } from 'firebase/auth';
import { auth } from './config'; 
import psbmsLogo from './Asset/background/psbms.webp';
import flyer from './Asset/background/flyer.jpg'
import bck1 from './Asset/background/bck1.jpg'; 
import bck2 from './Asset/background/bck2.jpg';
// import flyer from './Asset/background/flyerM.webp'
import shopping from './Asset/background/shopping.png'
import { useNavigate } from 'react-router-dom';

const DashBoard = () => {
    const {currentUser} = useAuthValue()
    const navigate = useNavigate()
    const ClickedPsbms = () => {
        navigate('/psbms')
    }
    const ClickedFlyer = () => {
        navigate('/ilndesign')
    }
    const ClickedEStore = () => {
        navigate('/ilnstore')
    }
    // cons 
    return ( 
        <div className='container'>
            <div className=''>
                <div className="container px-4 py-2" id="featured-3">
                    <h2 className="pb-2">DashBoard</h2> 
                    {/* border-bottom */}
                    <div className="row">
                        <div className="col-8">
                            <h6>E-mail:<span style={{color:'blue'}}>{currentUser?.email}</span></h6>
                        </div>
                        <div className="col-4 ">
                            <div className="row">
                                <div className="col-auto">
                                    <button type="button" className="btn btn-sm btn-primary position-relative">
                                        Inbox 
                                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                            5+
                                            <span className="visually-hidden">unread messages</span>
                                        </span>
                                    </button>
                                </div>
                                {/* <div className="col-auto">
                                    <button className="btn-primary rounded" onClick={() => signOut(auth)}>Sign Out</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    
                    
                    <div className="row g-4 py-1 row-cols-1 row-cols-lg-3">
                        <div className="feature col">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">    
                                {/* <svg className="bi" width="1em" height="1em"><use xlinkHref="#collection"/></svg>                             */}
                            </div>
                            <h2>Subscription Services</h2>
                            <hr/>
                            <p>
                               Click here to check the services that you I have with our compnay. 
                               This sample maintenance contract is between the owner of a single
                               property and a maintenance contractor. We offer many services
                               [contractor who is responsible for his or her own 
                                employment taxes and insurance.]
                            </p>
                            <a href="#" className="icon-link d-inline-flex align-items-center">
                                Services
                            </a>
                        </div>

                        <div className="feature col">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                                {/* <svg className="bi" width="1em" height="1em"><use xlink:href="#people-circle"/></svg> */}
                            </div>
                            <h2>My Course</h2>
                            <hr/>
                            <p> 
                                This sample maintenance contract is between the owner of a 
                                single property and a maintenance contractor. Click here to 
                                see the courses available and the course that you have signed 
                                up to [contractor who is responsible for his or her own 
                                employment taxes and insurance.]
                            </p>
                            <a href="#" className="icon-link d-inline-flex align-items-center">
                            my course            
                            </a>
                        </div>

                        <div className="feature col">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">                               
                            </div>
                            <h2>Contrats</h2>
                            <hr/>
                            <p>
                                This sample maintenance contract is between the owner of a single 
                                property and a maintenance contractor. The contract specifies the 
                                service provider is not an employee of the owner, but rather, an 
                                independent contractor who is responsible for his or her own 
                                employment taxes and insurance.
                            </p>
                            <a href="/contrats" className="icon-link d-inline-flex align-items-center">
                            Contrats
                            </a>
                        </div>
                    </div>
                </div>
                <div className="b-example-divider"></div>
                <div className="container px-4 py-3" id="custom-cards">
                    <h2 className="pb-2 border-bottom">Our Technology</h2>
                    <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5"> 
                        <div className="col">              
                            <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" 
                                style={{backgroundImage: `url(${psbmsLogo})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}
                                onClick={ClickedPsbms}
                            > 
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                                    <h2 className="pt-5 mt-5 mb-4 py-0 display-6 lh-1 fw-bold text-light "></h2>
                                    <ul className="d-flex list-unstyled mt-auto">
                                        <li className="me-auto">                
                                        </li>
                                        <li className="me-auto">                                                   
                                            <h2 className=" pt-4 mt-5 mb-0 py-0  display-6 lh-1 text-center fw-bold text-success ">PSBMS</h2>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                            {/* --- */}
                        <div className="col">              
                            <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" 
                                style={{backgroundImage: `url(${flyer})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}
                                onClick={ClickedFlyer}
                            > 
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                                    <h2 className="pt-5 mt-5 mb-4 py-0 display-6 lh-1 fw-bold text-light "></h2>
                                    <ul className="d-flex list-unstyled mt-auto">
                                        <li className="me-auto">                
                                        </li>
                                        <li className="me-auto">                                                   
                                            <h2 className=" pt-4 mt-4 mb-0 py-0  display-6 lh-1 text-center fw-bold text-success">Flyer Maker</h2>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>                        
                        {/* --- */}
                        <div className="col">              
                            <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" 
                                style={{backgroundImage: `url(${shopping})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}
                                onClick={ClickedEStore}
                            > 
                                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                                    <h2 className="pt-5 mt-5 mb-4 py-0 display-6 lh-1 fw-bold text-light "></h2>
                                    <ul className="d-flex list-unstyled mt-auto">
                                        <li className="me-auto">                
                                        </li>
                                        <li className="me-auto">                                                   
                                            <h2 className=" pt-4 mt-4 mb-0 py-0  display-6 lh-1 text-center fw-bold text-success">Shopping Store</h2>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* --- */}
                        
                    </div>
                    
                </div>
            </div>
            {/* another thing */}
            <div className='container'>
                <div className="row">
                    <div className="col-8" style={{border: '0px solid black'}}>
                        <h1>Current Status</h1>
                        <div className='row'>
                            <div className="col-auto">
                                <div className="card mb-3" >
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                        <img src={bck2} className="img-fluid rounded-start" alt="..."/>
                                        </div>
                                        <div className="col-md-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Courses</h5>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="card mb-3" >
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                        <img src={bck1} className="img-fluid rounded-start" alt="..."/>
                                        </div>
                                        <div className="col-md-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Services</h5>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                       
                    </div>
                    <div className="col-4 border">
                        <h1>Announcements</h1><hr/>
                        <div>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </div><hr/>
                        <div>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </div><hr/>
                        <div>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </div><hr/>
                    </div>
                </div>

            </div>
            {/* logout */}
            
            <br/>
        </div>
     );
}
 
export default DashBoard;