import React, { useState, useEffect } from 'react';
import { db } from '../../../config';
import PersonalBusiness from '../PersonalBusiness';
import { collection, query, onSnapshot } from 'firebase/firestore';

const GetDataFire = () => {
    const [loading,setLoading] = useState(false)
    const [dataset,setDataSet] = useState()
    
    useEffect(() => {
        const dataArray = []
        const q = query(collection(db,"budgetTable")); 
        const result = onSnapshot(q,(querysnapshot) =>{
            querysnapshot.forEach((doc) =>{
                dataArray.push({
                    ...doc.data(),
                    key: doc.id,
                });
            });
            setDataSet(dataArray)
            setLoading(true)
        });
        return () => result();
    },[])
    return (
        <div>
            {loading ? 
                <PersonalBusiness dataSet={dataset} setDataSet={setDataSet}/> : 
                <div className="text-center">
                    <div className="spinner-border" role="status" style={{width:'4rem',height:'4rem'}}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            } 
        </div>
    )
   
}
export default GetDataFire;