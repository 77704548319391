import React from 'react';
import Bck4 from '../Asset/background/bck4.jpg';
import './cssStyle/SlideShow.css';
import TypeMessage from './TypeMessage';

const SlideShow = () => {
    return ( 
        <div className='bg-gray'>
            <TypeMessage/>
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner" data-bs-interval="210">
                    <div className="carousel-item active"  data-bs-interval="3000">                    
                        <img src={Bck4} className="d-block w-100" alt="..."/>
                    </div>
                </div>

            </div>
        </div>
     );
}
 
export default SlideShow;