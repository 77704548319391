import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const RegisterCourse = () => {
    const location  = useLocation()
    const courseName = location.state
    return ( 
        <div className='container'>
            <h1>Register for {courseName}</h1>
        </div>
     );
}
 
export default RegisterCourse;