import React, { useState, useEffect } from 'react';
import { addDoc,collection, doc, setDoc } from 'firebase/firestore';
import { useNavigate, useLocation} from 'react-router-dom';
import { db } from '../../../config';
import { useAuthValue } from '../../../AuthenContext';

const MonthlyGoal = () => {
    const [groupFormState, setGroupFormState] = useState({       
        expensesName:"",
        amount:"",
        priority:"",
        userid: ""
    })
    const {currentUser} = useAuthValue()
    const navigate = useNavigate()
    const handleSubmit = (event) => {
        event.preventDefault();
        const dbref = addDoc(collection(db,"monthlygoal"),addRecord)
        const timeout = setTimeout(() => {
            console.log('this will be called after 2 sec')
        },6000);
        clearTimeout(timeout);
        console.log('finished')
        navigate('/psbms')
    }
    const handleChange = (e) => {
        const {name,value} = e.target;
        setGroupFormState({
            ...groupFormState,
            [name]:value,
        });
    }
    const addRecord = {
        expensesName:groupFormState.expensesName,
        amount:groupFormState.amount,
        priority:groupFormState.priority,
        userid: currentUser.uid
    }
    return ( 
        <div className='container py-2 '>
            <form onSubmit={handleSubmit}>
            <h4>Monthly Goal</h4>
            <hr/>
            <div className='row g-3 align-items-center'>
                <div className='col-auto'>
                    <input type="text" id="inputexpenseid" name="expensesName" className='form-control' aria-describedby='textHelpInline' placeholder='Expenses Name' onChange={handleChange}/>
                </div>    

                <div className='col-auto'>
                    <input type="text" id="inputexpenseid" name="amount" className='form-control' aria-describedby='textHelpInline' placeholder='Amount' onChange={handleChange}/>
                </div>    

                <div className='col-auto'>
                    {/* <input type="text" id="priority" name="priority" className='form-control' aria-describedby='textHelpInline' placeholder='Priority' onChange={handleChange}/> */}
                    <select name="priority" id="priority" className='form-select' aria-describedby='textHelpInline' placeholder='Priority' onChange={handleChange}>
                        <option value="">Choose...</option>
                        <option value="High">high</option>
                        <option value="medium">medium</option>
                        <option value="low">low</option>
                    </select>

                    {/* <select name="usstate" id="state" className="form-select" onChange={handleChange}>
                                        <option value="">Choose...</option>
                                        <option value="Texas">Texas</option>
                                        <option value="California">California</option>
                                    </select> */}
                </div>   
            </div><br/>
            <button className='btn btn-success' >Submit New Record</button>
            </form>
        </div>
     );
}
 
export default MonthlyGoal;