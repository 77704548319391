import React, {useState, useEffect} from 'react';


const GiftExachange = () => {
    const [dataF,setDataF] = useState({
        nom:"",
        cadeau1:"",
        cadeau2:"",
        urls: "",
        randNumIden:0
    })
    const dataObject = [
        {
            nom : 'Dorcas Itipo',
            cadeau1: 'Iphone 12',
            cadeau2: 'Chaussures size 40',
            urls: "www.amazon.com", 
            randNumIden: 23
        },

        {
            nom : 'Blanje Itipo',
            cadeau1: 'Iphone 11 ou 12',
            cadeau2: 'pagne',
            urls: "www.amazon.com", 
            randNumIden: 23
        },


    ]
    const [nom,setNom] = useState();

    const handleChange = (e) =>{
        const {name, value } = e.target; 
        console.log(name,'-',value)
        setDataF({...dataF,[name]:value})
    }

    // console.log('data name: ',dataF.name)
    console.log('data all: ',dataF)
    return ( 
        <div> 
            <div className='container'>
                <h1 className='text-center'>Our gift exchange <br/> assignment system.</h1>
                <p>you can use our system to plan our your organization will assign individu name and the gift that they would like to be offered.</p>
               
                <hr/>
                <div className="row g-3 align-items-center">
                    <div className="col-auto">
                        <label htmlFor="name" className='col-form-label'>Votre Nom: </label>
                    </div>

                    <div className="col-auto">
                        <input type="text" id='name' name='nom' className="form-control" onChange={handleChange}/>
                    </div>

                    <div className="col-auto">
                        <span className="form-text" id='nom'>ecrivez votre nom complet</span>
                    </div>
                </div>
                <br/>
                <div className="row g-3 align-items-center">
                    <div className="col-auto">
                        <label htmlFor="cadeau1" className='col-form-label'>Cadeau N1:</label>
                    </div>

                    <div className="col-auto">
                        <input type="text" id='cadeau1' name='cadeau1' className="form-control" onChange={handleChange} />
                    </div>

                    <div className="col-auto">
                        <span className="form-text" id='cadeau1'>ecrivez votre cadeau numero 1</span>
                    </div>
                </div>
                <br/>
                <div className="row g-3 align-items-center">
                    <div className="col-auto">
                        <label htmlFor="cadeau2" className='col-form-label'>Cadeau N2:</label>
                    </div>

                    <div className="col-auto">
                        <input type="text" id='cadeau2' name='cadeau2' className="form-control" onChange={handleChange}/>
                    </div>

                    <div className="col-auto">
                        <span className="form-text" id='cadeau2'>ecrivez votre cadeau numero 2</span>
                    </div>
                </div>
                <br/>
                <button className="btn-primary">Envoyer</button>
                <br/><br/>
            </div>
           <div>
            <h2>{dataF.name}</h2>
           </div>

        </div>
     );
}
 
export default GiftExachange;